import {
  AlertBlock,
  Button,
  ButtonColors,
  Loading,
  MakeBrowserWait,
} from '@chiroup/components';
import {
  NUMBER_ANY_HASH,
  STRING_BOOLEAN_HASH,
} from '@chiroup/core/constants/globals';
import { ChiroUpTransactionCommon } from '@chiroup/core/constants/stringConstants';
import { balanceFromItems } from '@chiroup/core/functions/balanceFromItems';
import { ChiroUpJSON } from '@chiroup/core/functions/ChiroUpJSON';
import { convertToMS } from '@chiroup/core/functions/convertToMS';
import { createDecimal } from '@chiroup/core/functions/createDecimal';
import { findNewDiscount } from '@chiroup/core/functions/findNewDiscount';
import { createDayjs } from '@chiroup/core/functions/time';
import { AppointmentInsuranceType } from '@chiroup/core/types/Appointment.type';
import { Insurance } from '@chiroup/core/types/PatientInsurance.type';
import {
  isaPaymentItem,
  isaServiceItem,
  isaTreatmentItem,
  PatientTransaction,
  PatientTransactionItemType,
  toTransactionItemSubtype,
  TransactionAppendItemsType,
  TransactionItemSubtypeEnum,
  TransactionItemTypeEnum,
  TransactionTypeEnum,
} from '@chiroup/core/types/PatientTransaction.type';
import { ClaimInfo } from '@chiroup/core/types/PatientVisit.type';
import {
  ArrowUpCircleIcon,
  CheckIcon,
  ClipboardIcon,
} from '@heroicons/react/24/outline';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  PlusCircleIcon,
} from '@heroicons/react/24/solid';
import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';
import { MeContext } from '../../../../../contexts/me.context';
import useApplicablePatientPackageList from '../../../../../hooks/useApplicablePatientPackageList';
import useResponsibilities from '../../../../../hooks/useResponsibilities';
import ButtonGroup, {
  ButtonGroupType,
} from '../../../../common/buttons/ButtonGroup';
import IconButton from '../../../../common/IconButton';
import useDisciplineTreatments from '../../../../settings/clinic/useDisciplineTreatments';
import ClaimInfoForm from '../../visits/notes/ClaimInfoForm';
import CodeEncounterDiagnosisServices from '../../visits/notes/services/CodeEncounterDiagnosisServices';
import AllocateNonBillableToPatientModal from './AllocteNonBillableToPatientModal';
import ChoosePackageModal from './ChoosePackageModal';
import {
  ActionsOnClick,
  BASE_TAB,
  ClassNames,
  EncounterContext,
  Phase,
  phases,
  RootCallbackEvent,
  RootCallbackProps,
} from './ConsolidatedTransactionModal';
import DiscountModal from './DiscountModal';
import LockButtonContent from './LockButtonContext';
import Payment from './Payment';
import PurchasingPackagesModal from './PurchasingPackagesModal';
import SingleAdHoc from './SingleAdHoc';
import SupplementsModal from './SupplementsModal';
import TransactionEditorTabContent from './TransactionEditorTabContent';
import TransactionEditorTabs from './TransactionEditorTabs';
import UnMergeButtonContent from './UnmergeContentButton';
import WriteOffClaimsModal from './WriteOffClaimsModal';
import WriteOffNonBillableBalanceModal from './WriteOffNonBillableBalanceModal';

dayjs.extend(tz);

type SingleProps = {
  actionsOnClick: (
    action: ActionsOnClick,
    transaction: PatientTransaction | null | undefined,
  ) => void;
  buttonColor: ButtonColors;
  context: NUMBER_ANY_HASH;
  currentPhase: Phase;
  encounterContext?: EncounterContext | null | undefined;
  hoverTextPrimary: string;
  isBalanceTransfer: boolean;
  isFetching: boolean;
  isNotReadOnly: boolean;
  isReadOnly: boolean;
  isSaving: boolean;
  itemId: string | null;
  mergeContext: any;
  ord: number;
  policies: Insurance[];
  refetch: () => void;
  rootCallback: (props: RootCallbackProps) => void;
  row: PatientTransaction;
  setBackgroundDirty: Dispatch<SetStateAction<boolean | null>>;
  setContext: Dispatch<SetStateAction<NUMBER_ANY_HASH>>;
  setCurrentPhase: Dispatch<SetStateAction<Phase>>;
  setPayAvailability: Dispatch<SetStateAction<STRING_BOOLEAN_HASH>>;
  synchronize: (args?: any) => void;
  textPrimary: string;
  trace?: boolean;
  close?: () => void;
  setTransactionManually: (transaction: PatientTransaction) => void;
  isLast?: boolean;
};
const SingleTransaction: React.FC<SingleProps> = ({
  actionsOnClick,
  context,
  currentPhase,
  encounterContext,
  hoverTextPrimary,
  isFetching,
  isNotReadOnly,
  isReadOnly,
  isSaving,
  itemId,
  mergeContext,
  ord,
  policies,
  setPayAvailability,
  refetch,
  rootCallback,
  row,
  setBackgroundDirty,
  setContext,
  setCurrentPhase,
  synchronize,
  textPrimary,
  trace,
  close,
  setTransactionManually,
  isLast = false,
}) => {
  /**
   * u s e S t a t e
   */
  // const [isBlahOpen, setIsBlahOpen] = useState<boolean>(false);
  const [copied, setCopied] = useState(false);
  const [isAnyRestRunning, setIsAnyRestRunning] = useState<boolean>(false);
  const [isWriteOffClaimsModalOpen, setIsWriteOffClaimsModalOpen] =
    useState<boolean>(false);
  const [expandedClaimInfo, setExpandedClaimInfo] = useState<boolean>(true);
  const [traceOpen, setTraceOpen] = useState<boolean>(!trace); // Initially closed.
  const [showDiscountModal, setShowDiscountModal] = useState<boolean>(false);
  const [showSupplementsModal, setShowSupplementsModal] =
    useState<boolean>(false);
  const [isPurchasePackageModal, setIsPurchasePackageModal] =
    useState<boolean>(false);
  const [isChoosePackageModal, setIsChoosePackageModal] =
    useState<boolean>(false);
  const [triggerAppendItems, setTriggerAppendItems] =
    useState<TransactionAppendItemsType | null>(null);

  const editingNewTransaction = useMemo(() => {
    return (row?.id ?? -1) === -1;
  }, [row]);

  const [activeSubtype, setActiveSubtype] = useState<string>('');
  const [isWritingOff, setIsWritingOff] = useState<boolean>(false);
  const [isAllocating, setIsAllocating] = useState<boolean>(false);
  const [openPaymentModal, setOpenPaymentModal] = useState<boolean>(false);

  const [showAllocacteNonBillableModal, setShowAllocateNonBillableModal] =
    useState<boolean>(false);
  const [fetchingAllocatableBalance] = useState<boolean>(false);
  const [showWriteOffNonBillableModal, setShowWriteOffNonBillableModal] =
    useState<boolean>(false);
  const [activeTabName, setActiveTabName] = useState<string>(BASE_TAB);

  // const [diagnosesOrder, setDiagnosesOrder] = useState<string[]>([]);

  const [incomingClaimInfo] = useState<ClaimInfo | null>(null);

  // const [isTransactionLocked, setIsTransactionLocked] =
  //   useState<boolean>(false);

  /**
   * H o o k s
   */
  const myRef = useRef(null);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { me, selectedLocationFull, /* hasRole, */ hasAccess } =
    useContext(MeContext);

  const { data: patientPackages } = useApplicablePatientPackageList({
    patientId: row?.patient?.id,
    locationId: row?.locationId,
  });

  const { data: disciplineTreatment } = useDisciplineTreatments(
    row?.treatmentId,
  );

  const clipboardIcon = useMemo(() => {
    // console.log(row);
    if (!row || !row.id || row?.id < 0) return null; // Not been saved yet.
    return (
      <div className="absolute right-0 top-0 pt-3">
        {copied ? (
          <CheckIcon className="h-6 w-6 text-green-500" />
        ) : (
          <div className="absolute right-0 top-0 pt-3">
            {copied ? (
              <CheckIcon className="h-6 w-6 text-green-500" />
            ) : (
              <IconButton
                className={`h-6 w-6 text-gray-400`}
                labelOnlyClassName="pt-1 ml-1"
                icon={<ClipboardIcon />}
                onClick={() => {
                  navigator.clipboard
                    .writeText(`/consolidated/transaction/${row?.billingKey}`)
                    .then(
                      () => {
                        setCopied(true);
                      },
                      (err) => {
                        console.error('Could not copy text: ', err);
                      },
                    );
                }}
                tooltip={`Copy the transaction URL to the clipboard.`}
                tooltipClassName="w-56"
              />
            )}
          </div>
        )}
      </div>
    );
  }, [copied, row]);

  const allocatableBalance = useMemo(() => {
    return (row as any)?.allocatableAmount;
  }, [row]);

  const isEncounterMode = useMemo(() => {
      return !!encounterContext;
    }, [encounterContext]),
    isNotEncounterMode = useMemo(() => {
      return !isEncounterMode;
    }, [isEncounterMode]);

  const nextEncounterDateDayjs = useMemo(() => {
    if (!row) return undefined;
    const localRow = row;
    return localRow?.nextRequestedEncounterDate
      ? createDayjs({
          datetime: convertToMS(localRow?.nextRequestedEncounterDate),
          timezone: selectedLocationFull?.timezone as string,
        })
      : undefined;
  }, [row, selectedLocationFull?.timezone]);

  const myUrl = useMemo(() => {
    return `/patients/${row?.patient?.id}/encounters/${row?.billingKey}`;
  }, [row?.billingKey, row?.patient?.id]);

  const myEncounter = useMemo(() => {
    const matches = pathname.match(/\/encounters\/([^/]+)/),
      res = matches?.[1] ?? '-bogus-';
    return res === row?.billingKey;
  }, [row?.billingKey, pathname]);

  const isScheduleBehind = useMemo(() => {
    return pathname?.includes('/schedule');
  }, [pathname]);

  const buttonGroupButtons = useMemo(() => {
    const buttons: ButtonGroupType[] = [];
    if (!row || isEncounterMode) return buttons;
    if (activeTabName !== 'Detail') return buttons;

    const transaction = row;
    if (
      !!transaction?.provider?.displayName?.length &&
      !!transaction?.nextRequestedEncounterDate
    ) {
      const nextEncounterDateDayjsStartOfDay = nextEncounterDateDayjs
        ?.hour(dayjs().hour())
        ?.startOf('hour');
      const thisAppointment = createDayjs({
        datetime: transaction?.transactionDate as number,
        timezone: selectedLocationFull?.timezone as string,
      }) as Dayjs;
      const hourOfThisAppointment = thisAppointment.hour() || 0;
      const minuteOfThisAppointment = thisAppointment.minute() || 0;

      const nextEncounterDateWithHour = nextEncounterDateDayjsStartOfDay
        ?.hour(hourOfThisAppointment)
        ?.minute(minuteOfThisAppointment)
        ?.valueOf();

      const button: ButtonGroupType = {
        label: nextEncounterDateDayjsStartOfDay
          ? 'Schedule appointment on ' +
            nextEncounterDateDayjsStartOfDay.format('M/DD/YYYY')
          : 'Next Appointment',
      };
      if (nextEncounterDateDayjsStartOfDay) {
        const patientNameWithoutPreferred =
          transaction?.patient?.displayName?.replace(/\s\(.*\)/g, '');
        const to = [
          `/schedule?endDate=${nextEncounterDateDayjsStartOfDay.format(
            'YYYY-MM-DD',
          )}`,
          `startDate=${nextEncounterDateDayjsStartOfDay.format('YYYY-MM-DD')}`,
          `startTime=${nextEncounterDateWithHour}`,
          `patient=${transaction?.patient?.id}`,
          `patientName=${encodeURIComponent(
            patientNameWithoutPreferred ?? '- no name -',
          )}`,
          `clinician=${transaction?.appointmentClinicianId}`,
          `treatment=${transaction?.treatmentId}`,
          `open=add`,
        ].join('&');
        if (isScheduleBehind) {
          button['onClick'] = () => {
            navigate(to);
            close?.();
          };
        } else {
          button.to = to;
        }
      } else {
        const to = [
          `/schedule?patient=${transaction?.patient?.id}`,
          `patientName=${transaction?.patient?.displayName}`,
          `clinician=${transaction?.appointmentClinicianId}`,
          `treatment=${transaction?.treatmentId}`,
          `open=add`,
        ].join('&');
        if (isScheduleBehind) {
          button['onClick'] = () => {
            navigate(to);
            close?.();
          };
        } else {
          button.to = to;
        }
      }
      // const button = {
      //   label: nextEncounterDateDayjs
      //     ? 'Schedule appointment on ' +
      //       nextEncounterDateDayjs.format('M/DD/YYYY')
      //     : 'Next Appointment',
      //   to: [
      //     `/schedule?endDate=${nextEncounterDateDayjs?.format('YYYY-MM-DD')}`,
      //     `startDate=${nextEncounterDateDayjs?.format('YYYY-MM-DD')}`,
      //     `patient=${transaction?.patient?.id}`,
      //     `patientName=${transaction?.patient?.displayName}`,
      //     `clinician=${transaction?.appointmentClinicianId}`,
      //     `treatment=${transaction?.treatmentId}`,
      //     `open=add`,
      //   ].join('&'),
      //   onClick: () => close?.(),
      // };
      buttons.push(button);
    } else {
      const to = [
        `/schedule?patient=${transaction?.patient?.id}`,
        `patientName=${transaction?.patient?.displayName}`,
        `clinician=${transaction?.appointmentClinicianId}`,
        `treatment=${transaction?.treatmentId}`,
        `open=add`,
      ].join('&');
      const button: ButtonGroupType = { label: 'Next Appointment' };
      if (isScheduleBehind) {
        button['onClick'] = () => {
          navigate(to);
          close?.();
        };
      } else {
        button.to = to;
      }
      buttons.push(button);
    }
    if (
      (!isEncounterMode && row.hasEncounter) ||
      (row.hasEncounter && !myEncounter)
    ) {
      if (!myEncounter) {
        buttons.push({
          label: 'Encounter',
          to: myUrl,
        });
      }
    }
    return buttons;
  }, [
    row,
    isEncounterMode,
    activeTabName,
    myEncounter,
    nextEncounterDateDayjs,
    isScheduleBehind,
    navigate,
    close,
    myUrl,
    selectedLocationFull?.timezone,
  ]);

  /**
   * u s e E f f e c t
   */

  useEffect(() => {
    if (!activeTabName) return;

    rootCallback?.({
      event: RootCallbackEvent.SetActiveTabName,
      item: {
        billingKey: row?.billingKey,
        tabname: activeTabName,
      },
    });
  }, [activeTabName, rootCallback, row?.billingKey]);

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => setCopied(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [copied]);

  useEffect(() => {
    // Get rid the link to my slot if I'm merged and empty.
    if (!(row?.merged && row?.items?.length === 0)) {
      setContext((prev) => ({
        ...prev,
        [ord]: {
          ...prev[ord],
          ref: myRef,
          merged: !!row?.merged,
        },
      }));
    }
  }, [
    mergeContext.isMerged,
    ord,
    row?.items?.length,
    row?.merged,
    setContext,
    synchronize,
  ]);

  /**
   * u s e M e m o
   */
  const isLocalReadOnly = useMemo(() => {
    return Boolean(isReadOnly || row?.merged);
  }, [isReadOnly, row?.merged]);

  // const userTz = useMemo(() => {
  //   return selectedLocationFull.timezone;
  // }, [selectedLocationFull]);

  // You can't unmerge if there are payments. [Seems like a sound decision.]
  const hasPayments = useMemo(() => {
    return row?.items?.filter((item) => isaPaymentItem(item)).length !== 0;
  }, [row?.items]);

  const invoiceTestingMessage = useMemo(() => {
    const obj = (row ?? {}) as any;
    return obj?.invoiceTesting ?? undefined;
  }, [row]);

  const visitForm = useMemo(() => {
    if (
      isNotEncounterMode ||
      !encounterContext ||
      !encounterContext.visitForm
    ) {
      return null;
    }
    return encounterContext.visitForm.id === row?.billingKey
      ? encounterContext.visitForm
      : null;
  }, [encounterContext, isNotEncounterMode, row?.billingKey]);

  const claimInfo = useMemo(() => {
    return visitForm?.encounterInfo?.claimInfo;
  }, [visitForm?.encounterInfo?.claimInfo]);

  const isAdHoc = useMemo(() => {
    return row?.type === TransactionTypeEnum.AdHoc;
  }, [row?.type]);

  const localServices = useMemo(() => {
    return row?.items?.filter((item) => isaServiceItem(item)) ?? [];
  }, [row?.items]);

  const isTransactionLocked = useMemo(() => {
    if (row?.merged) return true;
    return localServices.some((item) => item.locked);
  }, [row, localServices]);

  const isLockAvailable = useMemo(() => {
    const isAlreadyLocked = localServices.some((item) => item.locked);
    if (isAlreadyLocked) return true;
    if (localServices.length === 0) return false;
    return localServices.every((item) => (item?.diagnoses?.length ?? 0) > 0);
  }, [localServices]);

  const showDueToTrace = useMemo(() => {
    return row?.merged && row?.items?.length === 0 && trace;
  }, [row?.items?.length, row?.merged, trace]);

  const nav = useMemo(() => {
    if (!context) return [];
    let visualNumber = 0;
    return Object.entries(context).map(([key, value], idx) => {
      const iKey = Number(key);
      if (iKey === ord) {
        visualNumber++;
        return (
          <li
            key={`nav-${ord}-${idx}`}
            className="flex w-3 h-3 font-sans text-xs font-semibold border text-white border-primary-600 bg-primary-600 p-3 rounded-full justify-center items-center cursor-pointer self-center"
          >
            {visualNumber}
          </li>
        );
      }
      if (iKey < 0 && ord === 0) return null; // No link to top on  first item.
      let display: ReactNode = null;
      if (iKey < 0) {
        display = <ArrowUpCircleIcon className="h-8 w-8" />;
      } else {
        if (!showDueToTrace && value?.merged) return null;
        visualNumber++;
        display = (
          <div className="flex items-center justify-center h-4 w-4 border border-gray-400 p-3 rounded-full hover:text-white hover:bg-primary-500 align-middle">
            {visualNumber}
          </div>
        );
      }
      if (!showDueToTrace && value?.merged) return null;
      return (
        <li
          key={`nav-${ord}-${idx}`}
          className={classNames(
            'flex align-middle font-sans text-sm font-semibold text-gray-400 justify-center items-center cursor-pointer',
            iKey < 0 ? `${textPrimary} ${hoverTextPrimary}` : ' ',
          )}
          onClick={(e: any) => {
            e.stopPropagation();
            e.preventDefault();
            context?.[iKey]?.ref?.current?.scrollIntoView({
              behavior: 'smooth',
            });
          }}
        >
          {display}
        </li>
      );
    });
  }, [context, hoverTextPrimary, ord, showDueToTrace, textPrimary]);

  const thisSectionClassName = useMemo(() => {
    let base =
      itemId === row?.billingKey && nav?.length > 2
        ? 'bg-primary-50 dark:bg-primary-900/20'
        : 'bg-white dark:bg-darkGray-800';

    if (myEncounter && nav?.length > 2) {
      base = 'bg-primary-50 dark:bg-primary-900/20';
    } else {
      base = showDueToTrace ? 'bg-yellow-200 dark:bg-yellow-900/20' : base;
    }
    return base;
  }, [itemId, myEncounter, nav?.length, row?.billingKey, showDueToTrace]);

  const discountAlreadyApplied = useMemo(() => {
    return row?.items?.some(
      (item) =>
        item?.subtype === TransactionItemSubtypeEnum.Adjustment ||
        item?.subtype === TransactionItemSubtypeEnum.Override,
    );
  }, [row?.items]);

  //returning an array of all the patient packages that can be used to apply to the service / treatment
  const canApplyPackage = useMemo(() => {
    let packageThatCanBeApplied: number[] = [];
    const alreadyPackageApplied = row?.items?.some((item) => item?.packageId);

    if (alreadyPackageApplied) return [];

    const itemsToUse = row?.insurances?.length
      ? row?.items?.filter((item) => !item?.insuranceBillable)
      : row?.items;

    const hasServices = itemsToUse?.filter(
      (item) =>
        item?.subtype === TransactionItemSubtypeEnum.Service ||
        item?.subtype === TransactionItemSubtypeEnum.PatientService,
    );

    //if there are no nonbillable services and patient has insurances, then no package can be applied
    if (!hasServices?.length && row?.insurances?.length) {
      return [];
    }

    if (hasServices?.length) {
      hasServices?.forEach((service) => {
        if (
          service?.code &&
          patientPackages?.data?.servicesCovered?.[service?.code as any]
        ) {
          packageThatCanBeApplied = packageThatCanBeApplied.concat(
            patientPackages?.data?.servicesCovered?.[service?.code as any],
          );
        }
      });
    } else if (
      row?.treatmentId &&
      patientPackages?.data?.treatmentsCovered?.[row?.treatmentId]
    ) {
      packageThatCanBeApplied = packageThatCanBeApplied.concat(
        patientPackages?.data?.treatmentsCovered?.[row?.treatmentId],
      );
    }

    return [...new Set(packageThatCanBeApplied)];
  }, [
    patientPackages?.data?.servicesCovered,
    patientPackages?.data?.treatmentsCovered,
    row?.insurances?.length,
    row?.items,
    row?.treatmentId,
  ]);

  const packageDisabled = useMemo(() => {
    return (
      currentPhase.name !== 'Edit' ||
      !row ||
      isFetching ||
      isSaving ||
      !canApplyPackage?.length ||
      row?.items?.some(
        (i) =>
          !!i?.packageId && i.subtype === TransactionItemSubtypeEnum.Adjustment,
      )
    );
  }, [row, currentPhase.name, isFetching, isSaving, canApplyPackage]);

  const patientBalance = useMemo(() => {
    return createDecimal(row?.patientBalance ?? 0);
  }, [row?.patientBalance]);

  const nonBillable = useMemo(() => {
    return row?.insurances?.some((i) => !i.billable);
  }, [row?.insurances]);

  const editingNew = useMemo(() => {
    return row?.billingKey === 'new';
  }, [row?.billingKey]);

  const isTransactionPaid = useMemo(() => {
    let resp = false;
    if (row?.type === TransactionTypeEnum.AdHoc) {
      const balance = row?.balance ?? 0;

      resp = balance <= 0;
    } else if (nonBillable) {
      if (Number(row?.patientBalance || 0) <= 0) {
        resp = true;
      }
      if (patientBalance.equals(0)) {
        resp = true;
      }
    } else {
      if (Number(row?.balance || 0) <= 0) {
        resp = true;
      }
    }
    // Can this be done in a useMemo??? Needed a timeout!!! [BWM: This sets a value in the container, so no.]
    setTimeout(() => {
      setPayAvailability?.((prev) => {
        const key = row?.billingKey;
        if (!key) return prev;
        return { ...prev, [key]: !resp };
      });
    }, 0);
    return resp;
  }, [
    row?.balance,
    row?.billingKey,
    row?.patientBalance,
    row?.type,
    nonBillable,
    patientBalance,
    setPayAvailability,
  ]);

  /**
   * H o o k s   a f t e r   u s e M e m o
   */
  const {
    patientResponsibility,
    insurancesToUse,
    responsibilities,
    payorResponsibilityTotal,
    balanceAllocatedToPatient,
    patientInsuranceResponsibility,
  } = useResponsibilities({
    workingTransaction: row,
  });

  /**
   * F u n c t i o n s
   */

  const findThePackageCredit = useCallback(
    ({
      packageId,
      items,
      insurances,
    }: {
      packageId?: number | null;
      items: PatientTransactionItemType[];
      insurances?: Partial<AppointmentInsuranceType>[];
    }) => {
      const packageToApply = patientPackages?.data?.packages?.find(
        (pkg) => pkg.ID === packageId,
      ) as any;

      const packageName = packageToApply?.packageName;

      //insurances could be an empty array so only want to use working transaction insurances if insurances is undefined
      const insuranceArrToUse =
        insurances !== undefined ? insurances : row?.insurances;

      const itemsToUse = insuranceArrToUse?.length
        ? items?.filter((item) => !item?.insuranceBillable)
        : items;
      const hasServices = itemsToUse?.some(
        (item) =>
          item?.subtype === TransactionItemSubtypeEnum.Service ||
          item?.subtype === TransactionItemSubtypeEnum.PatientService,
      );

      let newCredit;

      if (!hasServices) {
        const costOfTreatment = items?.find(
          (item) =>
            item.type === TransactionItemTypeEnum.Debit &&
            item.subtype === TransactionItemSubtypeEnum.Treatment,
        )?.amount;
        newCredit = toTransactionItemSubtype(
          TransactionItemSubtypeEnum.Adjustment,
          {
            amount: costOfTreatment,
            description: `${packageName} - (package applied)`,
            packageId: packageId,
            adjustment: { structure: '$ Discount' },
          },
        );
        newCredit.id = v4();
      } else {
        const doesPackageCoverTreatment =
          patientPackages?.data?.treatmentsCovered?.[row?.treatmentId || -1];

        const codesAssociatedWithTreatment = doesPackageCoverTreatment
          ? disciplineTreatment?.referenceCodes?.map((code) => code.code)
          : [];

        const servicesThatCanBeCredited = [
          ...(codesAssociatedWithTreatment || []),
          ...(Object.keys(patientPackages?.data?.servicesCovered || {}) || []),
        ];

        const totalCoveredByPackage = itemsToUse?.reduce((acc, item) => {
          if (servicesThatCanBeCredited.includes(item.code as string)) {
            acc = acc.plus(
              createDecimal(item?.amount || 0).times(item?.units || 1),
            );
          }
          return acc;
        }, createDecimal(0));

        newCredit = toTransactionItemSubtype(
          TransactionItemSubtypeEnum.Adjustment,
          {
            amount: totalCoveredByPackage?.toFixed(2),
            description: `${packageName} - (package applied)`,
            packageId: packageId,
            adjustment: { structure: '$ Discount' },
          },
        );
        newCredit.id = v4();
      }

      return newCredit;
    },
    [
      patientPackages?.data?.packages,
      patientPackages?.data?.treatmentsCovered,
      patientPackages?.data?.servicesCovered,
      row?.insurances,
      row?.treatmentId,
      disciplineTreatment?.referenceCodes,
    ],
  );

  const genDupeKey = (item: PatientTransactionItemType) => {
    return `${item?.code}|${item?.type}|${item?.description}|${item?.amount}|${item?.subtype}`;
  };

  const addPendingItems = useCallback(
    (items: PatientTransactionItemType[]) => {
      if (!row) return;
      if (!items || !items?.length) return;
      const newobj = ChiroUpJSON.clone(row);
      newobj.items = newobj.items || [];
      const nextItems = ChiroUpJSON.clone(newobj.items);

      /**
       * If we have dupes, just increase the units.
       *
       * 1) Generate a detection key for each item so we can track the # of units.
       * 2) Go through the new items and just update the units of any existing matches.
       *    Otherwise, just add the new item to the list.
       */
      const nextKeys = nextItems.reduce(
        (a: any, c: PatientTransactionItemType) => {
          const key = genDupeKey(c);
          if (!a[key]) {
            a[key] = c.units || 1;
            return a;
          }
          a[key] = a[key] + 1;
          return a;
        },
        {},
      );
      /**
       * Add what needs to be added or just increment the units if we have detected
       * a duplicate.
       */
      items.forEach((item) => {
        const key = genDupeKey(item);
        if (nextKeys[key]) {
          nextKeys[key] = nextKeys[key] + item.units;
        } else {
          nextItems.push(item);
        }
      });

      /**
       * True up the units on the items from the hash.
       */
      nextItems.forEach((item: PatientTransactionItemType) => {
        const key = genDupeKey(item);
        if (nextKeys[key]) {
          item.units = nextKeys[key];
        }
      });

      newobj.items = nextItems;
      /*** end of keeping out the dupes */
      const percentDiscountApplied = newobj?.items?.find(
        (item: { subtype: string; adjustment?: { percentOff: number } }) =>
          item.subtype === 'Adjustment' && item?.adjustment?.percentOff,
      );

      const overrideApplied = newobj?.items?.find(
        (item: { subtype: string; adjustment?: { structure: string } }) =>
          item.subtype === TransactionItemSubtypeEnum.Override,
      );
      if (percentDiscountApplied && !overrideApplied) {
        const returnedItems = findNewDiscount(newobj.items);

        newobj.items = returnedItems;
      }
      const servicesToAdd = items?.filter(
        (obj: PatientTransactionItemType) =>
          obj.subtype === TransactionItemSubtypeEnum.Service ||
          obj.subtype === TransactionItemSubtypeEnum.PatientService,
      );
      if (servicesToAdd && servicesToAdd.length) {
        setTriggerAppendItems((p) => ({ ...p, services: servicesToAdd }));
      }

      rootCallback?.({
        event: RootCallbackEvent.UpdateSingleTransaction,
        transaction: newobj,
      });

      setActiveSubtype('');
    },
    [rootCallback, row],
  );

  const applyPackage = useCallback(
    (id: number) => {
      const packageToApply = patientPackages?.data?.packages?.find(
        (pkg) => pkg.ID === id,
      ) as any;

      const packageName = packageToApply?.packageName;
      const newCredit = findThePackageCredit?.({
        packageId: packageToApply?.ID,
        items: row?.items || [],
      });

      newCredit.id = v4();
      const itemsToAdd = [newCredit];

      const anyAdditionalFee = packageToApply?.additionalPayment;
      if (anyAdditionalFee && anyAdditionalFee > 0) {
        //need to add a Debit.......
        const additionalFee = toTransactionItemSubtype(
          TransactionItemSubtypeEnum.AdditionalFee,
          {
            amount: anyAdditionalFee,
            description: `${packageName} - (additional fee)`,
            packageId: id,
          },
        );
        additionalFee.id = v4();
        itemsToAdd.push(additionalFee);
      }

      return addPendingItems(itemsToAdd);
    },
    [
      patientPackages?.data?.packages,
      findThePackageCredit,
      row?.items,
      addPendingItems,
    ],
  );

  /**
   * Some modals appear multiple places and need the same behavior. This
   * is a place to put it. Should speed up finding problems? [He hopes.]
   */
  const MODALS = useMemo(() => {
    return {
      DiscountModal: {
        isAnyRestRunning,
        callbacks: {
          onClose: () => setShowDiscountModal(false),
          onAddStart: () => {
            // console.log('DiscountModal: onAddStart 03');
            setIsAnyRestRunning(true);
          },
          onAdded: (items: any) => {
            // console.log('DiscountModal: onAdded 03');
            setIsAnyRestRunning(false);
            setShowDiscountModal(false);
          },
        },
      },
    };
  }, [isAnyRestRunning]);

  /**
   * Not sure this is better, but the idea is to make the
   * JSX easier to read by encapsulating the button state
   * logic in one place.
   *
   * We also use the same buttons multiple places.
   *
   */
  const BUTTON = useMemo(() => {
    const packageBought = row?.items?.some(
      (item: any) => item.subtype === TransactionItemSubtypeEnum.Package,
    );
    return {
      // blah: {
      //   text: 'Blah',
      //   className: 'flex-none',
      //   color: ButtonColors.primary,
      //   disabled: currentPhase.name === 'Edit' || isFetching || isSaving,
      //   omitWhenDisabled: true,
      // },
      writeOffBillable: {
        text: 'Write-Off',
        className: 'flex-none',
        color: ButtonColors.primary,
        disabled: currentPhase.name === 'Edit' || isFetching || isSaving,
        omitWhenDisabled: true,
      },
      writeOff: {
        text: 'Write-Off',
        color: ButtonColors.plainWithBorder,
        onClick: () => setShowWriteOffNonBillableModal(true),
        trivialTooltip: {
          text: !allocatableBalance ? 'There is no allocatable balance.' : '',
          type: 'info',
          iconClassName: 'h-3 w-3 text-black inline-block ml-2 align-text-top',
        },
        disabled:
          currentPhase.name !== 'View' ||
          !allocatableBalance ||
          isFetching ||
          isSaving ||
          fetchingAllocatableBalance ||
          isWritingOff ||
          isAllocating,
      },
      addPackage: {
        text: 'Add Package',
        className: 'flex-none', // Use flex-none to prevent the button from growing
        icon: <PlusCircleIcon className="h-6 w-6" />,
        color: ButtonColors.plainWithBorder,
        omitWhenDisabled: true,
        disabled:
          currentPhase.name === 'View' ||
          isFetching ||
          isSaving ||
          isTransactionLocked ||
          packageBought,
        trivialTooltip: {
          text: packageBought
            ? 'Only one package purchase is allowed per transaction.'
            : '',
          type: 'info',
          iconClassName: 'h-3 w-3 text-black inline-block ml-2 align-text-top',
        },
      },
      allocateBalance: {
        text: 'Allocate Balance',
        color: ButtonColors.plainWithBorder,
        className: 'flex-none',
        disabled:
          currentPhase.name === 'Edit' ||
          isNotReadOnly ||
          !allocatableBalance ||
          !row ||
          isFetching ||
          isSaving ||
          fetchingAllocatableBalance ||
          isWritingOff ||
          isAllocating,
      },
      applyPackage: {
        text: 'Apply package',
        color: ButtonColors.plainWithBorder,
        className: 'flex-none', // Use flex-none to prevent the button from growing
        trivialTooltip: row?.insurances?.length
          ? {
              text: 'Only non billable services can be covered by a package',
            }
          : {
              text: '',
            },
        disabled: packageDisabled,
        omitWhenDisabled: true,
      },
      discount: {
        text: 'Discount',
        className: 'flex-none', // Use flex-none to prevent the button from growing
        onClick: () => {
          // console.log({ help: '!!!!!!!' });
          setShowDiscountModal(true);
          return;
        },
        disabled:
          currentPhase.name !== 'Edit' ||
          !row ||
          isFetching ||
          isSaving ||
          isTransactionLocked ||
          discountAlreadyApplied,
      },
      pay: {
        text: 'Pay',
        color: ButtonColors.plainWithBorder,
        className: 'flex-none -ml-4', // Use flex-none to prevent the button from growing
        tooltipIconClassName:
          'h-5 w-5 text-gray-700 inline-block ml-2 align-text-top',
        tooltip:
          Number(row?.patientBalance ?? 0) === 0 &&
          Number(row?.balance ?? 0) !== 0
            ? 'There is no expected patient balance based on the applied insurance.'
            : undefined,
        disabled:
          Number(row?.patientBalance ?? 0) === 0 ||
          currentPhase.name === 'Edit' ||
          isNotReadOnly ||
          !row ||
          isFetching ||
          isSaving ||
          isWritingOff ||
          isAllocating ||
          isTransactionPaid,
      },
      supplementsAndSupplies: {
        text: 'Add Supplements/Supplies',
        className: 'flex-none', // Use flex-none to prevent the button from growing
        icon: <PlusCircleIcon className="h-6 w-6" />,
        color: ButtonColors.plainWithBorder,
        onClick: () => {
          setShowSupplementsModal(true);
        },
        disabled:
          currentPhase.name === 'View' ||
          isFetching ||
          isSaving ||
          isTransactionLocked,
      },
    };
  }, [
    row,
    currentPhase.name,
    isFetching,
    isSaving,
    allocatableBalance,
    fetchingAllocatableBalance,
    isWritingOff,
    isAllocating,
    isTransactionLocked,
    isNotReadOnly,
    packageDisabled,
    discountAlreadyApplied,
    isTransactionPaid,
  ]);

  const CLICKS = {
    addLineItem: (subtype: TransactionItemSubtypeEnum | string) => {
      // console.log('CLICKS.addLineItem', subtype);
      if (subtype === TransactionItemSubtypeEnum.Adjustment) {
        setShowDiscountModal(true);
        return;
      }
      setActiveSubtype(subtype);
    },
    new: (e: any) => {
      const newTxn = ChiroUpJSON.clone(row);
      newTxn.items.push({});
      newTxn.balance = balanceFromItems({
        startingBalance: newTxn.startingBalance,
        items: newTxn.items,
        productTaxRate: selectedLocationFull?.productTaxRate,
        serviceTaxRate: selectedLocationFull?.serviceTaxRate,
        transactionType: newTxn.type,
      }).balance;

      rootCallback?.({
        event: RootCallbackEvent.UpdateSingleTransaction,
        transaction: newTxn,
      });
    },
    add: (e: any) => {
      const newTxn = ChiroUpJSON.clone(row);
      newTxn.items.push({});
      newTxn.balance = balanceFromItems({
        startingBalance: newTxn.startingBalance,
        items: newTxn.items,
        productTaxRate: selectedLocationFull?.productTaxRate,
        serviceTaxRate: selectedLocationFull?.serviceTaxRate,
        transactionType: newTxn.type,
      }).balance;

      rootCallback?.({
        event: RootCallbackEvent.UpdateSingleTransaction,
        transaction: newTxn,
      });
    },
  };

  // This is done once. Lots of dependencies, easier than making a real component.
  const actionButtonsComponent = useMemo(() => {
    return (
      <div className="w-full flex flex-row justify-end space-x-4 mt-4">
        {!!canApplyPackage?.length && (
          <Button
            {...BUTTON.applyPackage}
            onClick={() =>
              canApplyPackage?.length > 1
                ? setIsChoosePackageModal(true)
                : applyPackage(canApplyPackage?.[0])
            }
          />
        )}
        {currentPhase.name === 'Edit' ? (
          discountAlreadyApplied ? null : (
            <Button {...BUTTON.discount} />
          )
        ) : (
          <Button {...BUTTON.pay} onClick={() => setOpenPaymentModal(true)} />
        )}
        {nonBillable &&
          !!allocatableBalance &&
          currentPhase.name !== 'Edit' && (
            <Button
              {...BUTTON.allocateBalance}
              onClick={() => setShowAllocateNonBillableModal(true)}
            />
          )}
        {!nonBillable &&
          !isAdHoc &&
          !row.superBill &&
          !row.courtesyBilling &&
          row.insurances &&
          row.insurances.length > 0 && (
            <>
              {/* <Button {...BUTTON.blah} onClick={() => setIsBlahOpen(true)} /> */}
              <Button
                {...BUTTON.writeOffBillable}
                onClick={() => setIsWriteOffClaimsModalOpen(true)}
              />
            </>
          )}
        {/* <pre>
          {ChiroUpJSON.pretty({
            nonBillable,
            isAdHoc,
            superBill: row?.superBill,
            courtesyBilling: row?.courtesyBilling,
            insurances: row?.insurances,
          })}
        </pre> */}
        {/* {isBalanceTransfer ? (
          <div>... balance transfers are not supported in this view ...</div>
        ) : null} */}
      </div>
    );
  }, [
    canApplyPackage,
    BUTTON.applyPackage,
    BUTTON.discount,
    BUTTON.pay,
    BUTTON.allocateBalance,
    BUTTON.writeOffBillable,
    currentPhase.name,
    discountAlreadyApplied,
    nonBillable,
    allocatableBalance,
    isAdHoc,
    row.superBill,
    row.courtesyBilling,
    row.insurances,
    applyPackage,
  ]);

  /**
   * R e t u r n s
   */
  if (row?.merged && row?.items?.length === 0 && !trace) {
    return null;
  }

  if (isEncounterMode && !row?.hasEncounter) {
    return null;
  }

  if (isEncounterMode && !myEncounter) {
    return null;
    // return isFetching ? null : (
    //   <div className={ClassNames.bg}>
    //     <cite>No such encounter.</cite>
    //   </div>
    // );
  }
  if (isEncounterMode) {
    return (
      <>
        <div
          data-component="in-encounter-mode"
          className={classNames(
            'flex flex-row space-x-8 dark:bg-darkGray-700',
            ClassNames.bg,
          )}
          ref={myRef}
          id={`slot-${row?.billingKey}`}
        >
          <div className="rounded-lg border overflow-hidden grow shadow-inner pt-0 px-4 pb-4 bg-white dark:bg-darkGray-700">
            <CodeEncounterDiagnosisServices
              {...encounterContext}
              codeEncounterDiagnosisServicesListProps={{
                componentClassName: 'w-full',
              }}
              patientId={encounterContext?.patientId || ''}
              clinicId={me?.selectedClinic?.ID ?? -1}
              parentIsa={'slot'}
              horizontal={true}
              patientTransaction={row}
              rootCallback={rootCallback}
              editMode={currentPhase.name === 'Edit'}
              afterImport={async (newTransaction: PatientTransaction[]) => {
                // console.log({ afterImport: { newTransaction } });
                for (const txn of newTransaction ?? []) {
                  txn.items = (txn?.items ?? []).map((i) => {
                    if (isaTreatmentItem(i)) {
                      i.amount = 0;
                    }
                    return i;
                  });
                  rootCallback?.({
                    event: RootCallbackEvent.UpdateSingleTransaction,
                    transaction: txn,
                  });
                }
              }}
              disciplineId={row?.disciplineId || undefined}
              instanceComponents={{
                lockButton: isLockAvailable ? (
                  <LockButtonContent
                    state={{
                      available: isLockAvailable,
                      locked: isTransactionLocked,
                    }}
                    transaction={row}
                    readonly={isLocalReadOnly}
                    isBillingStarted={row?.isBillingStarted}
                    onClick={(e: any) => {
                      actionsOnClick(ActionsOnClick.Lock, row);
                    }}
                    componentClassName={[
                      'absolute',
                      'flex-row',
                      'flex',
                      'font-medium',
                      'font-sans',
                      'justify-end',
                      'mb-4',
                      'p-1',
                      'pl-1',
                      'right-0',
                      'space-x-2',
                      'text-sm',
                      'top-2',
                      'w-24',
                    ].join(' ')}
                  />
                ) : null,
                unMergeButton: <div data-message="unused-unmerge-button" />,
              }}
              // onChangeConsolidatedTransaction={onChangeConsolidatedTransaction}
              policies={policies}
            />
            {visitForm ? (
              <div className="mt-6">
                <div
                  className="flex flex-row space-x-2 text-primary-600 cursor-pointer"
                  onClick={(e: any) => {
                    e?.stopPropagation?.();
                    e?.preventDefault?.();
                    setExpandedClaimInfo(!expandedClaimInfo);
                  }}
                >
                  <div className="mb-2.5 block relative text-lg font-medium leading-5 sm:mt-px sm:pt-2 ">
                    Claim Info
                  </div>
                  <div className="pt-2">
                    {expandedClaimInfo ? (
                      <ChevronUpIcon className="w-5 h-5" />
                    ) : (
                      <ChevronDownIcon className="w-5 h-5" />
                    )}
                  </div>
                </div>
                {!!visitForm?.insuranceSubmitted?.length && (
                  <AlertBlock
                    level="caution"
                    message={ChiroUpTransactionCommon.billingStarted}
                  />
                )}
                {expandedClaimInfo ? (
                  <form>
                    <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-300 p-4 mb-6">
                      <div className="space-y-1 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4">
                        <ClaimInfoForm
                          value={visitForm}
                          setVisitForm={encounterContext?.setVisitForm}
                        />
                      </div>
                    </div>
                    <div
                      className={`mt-6 grid gap-3 grid-flow-row-dense ${
                        visitForm?.signed ? 'w-full' : 'grid-cols-2'
                      }`}
                    ></div>
                  </form>
                ) : null}
              </div>
            ) : null}
            {trace ? (
              <div className="mt-4">
                <Button
                  onClick={() => setTraceOpen(!traceOpen)}
                  text="Toggle #1"
                  color={ButtonColors.plainWithBorder}
                />
                <div className={classNames('p-4', traceOpen ? '' : 'hidden')}>
                  {/* <pre>{ChiroUpJSON.pretty(localRow)}</pre> */}
                  <h1>row</h1>
                  <pre>
                    {ChiroUpJSON.pretty({
                      id: row?.id,
                      billingKey: row?.billingKey,
                      provider: row?.provider,
                      type: row?.type,
                      merged: row?.merged,
                      hasPayments,
                      // encounterContext,
                    })}
                  </pre>
                  <hr />
                  <pre>
                    {ChiroUpJSON.pretty({ claimInfo, incomingClaimInfo })}
                  </pre>
                  <hr />
                  <pre>
                    {ChiroUpJSON.pretty({
                      localRowInsurances: row?.insurances,
                    })}
                  </pre>
                </div>
              </div>
            ) : null}{' '}
          </div>
        </div>
        <MakeBrowserWait
          isWaiting={isFetching || isSaving || isAnyRestRunning}
        />
      </>
    );
  }

  if (isAdHoc) {
    return (
      <>
        <div
          className={classNames('flex flex-row space-x-8', ClassNames.bg)}
          ref={myRef}
          id={`slot-${row?.billingKey}`}
        >
          <div className="rounded-lg border overflow-hidden grow shadow-inner pt-0 px-4 pb-4 bg-white dark:bg-darkGray-700">
            <div className="relative">
              <TransactionEditorTabs
                setActiveTabName={setActiveTabName}
                switches={{
                  Activity: !editingNewTransaction,
                  Invoices: !editingNewTransaction,
                  Detail: !editingNewTransaction,
                }}
                callbacks={{
                  onFromDetail: (e: any, tab: any) => {
                    if (isNotReadOnly) {
                      rootCallback?.({
                        event: RootCallbackEvent.AddMessageItem,
                        item: {
                          id: 'move-tab-while-editing',
                          title: 'Please cancel editing before changing tabs.',
                        },
                      });
                      return false;
                    }
                  },
                }}
              />
              {clipboardIcon}
            </div>
            <SingleAdHoc
              actionsOnClick={actionsOnClick}
              context={context}
              currentPhase={currentPhase}
              isFetching={isFetching}
              isReadOnly={isLocalReadOnly}
              isSaving={isSaving}
              parentProperties={{
                BUTTON,
                actionButtonsComponent,
                activeSubtype,
                activeTabName,
                addPendingItems,
                balanceAllocatedToPatient,
                // clear,
                editingNew,
                fetchingAllocatableBalance,
                findThePackageCredit,
                isAllocating,
                isTransactionPaid,
                isWritingOff,
                nonBillable,
                openPaymentModal,
                packageDisabled,
                patientPackages,
                patientResponsibility,
                payorResponsibilityTotal,
                patientInsuranceResponsibility,
                responsibilities,
                setActiveSubtype,
                setIsChoosePackageModal,
                setOpenPaymentModal,
                setShowAllocateNonBillableModal,
                setShowSupplementsModal,
                showSupplementsModal,
                setIsPurchasePackageModal,
                triggerAppendItems,
                isTransactionLocked,
                hasAccess,
                discountAlreadyApplied,
                CLICKS,
              }}
              row={row}
              setCurrentPhase={setCurrentPhase}
              rootCallback={rootCallback}
              trace={trace}
              setTransactionManually={setTransactionManually}
            />
          </div>
        </div>
        {showDiscountModal ? (
          <DiscountModal
            patientId={row?.patient?.id || ''}
            transaction={row}
            addPendingItems={addPendingItems}
            isOpen={true}
            isRouted={false}
            {...MODALS.DiscountModal}
          />
        ) : null}
        {showSupplementsModal ? (
          <SupplementsModal
            patientId={row?.patient?.id || ''}
            transaction={row}
            addPendingItems={addPendingItems}
            isOpen={true}
            isRouted={false}
            onClose={() => setShowSupplementsModal(false)}
          />
        ) : null}
        {isPurchasePackageModal ? (
          <PurchasingPackagesModal
            patientId={row?.patient?.id || ''}
            transaction={row}
            isRouted={false}
            isOpen={true}
            addPendingItems={addPendingItems}
            onClose={() => setIsPurchasePackageModal(false)}
          />
        ) : null}
        <MakeBrowserWait
          isWaiting={isFetching || isSaving || isAnyRestRunning}
        />
        {row && openPaymentModal && (
          <Payment
            passedTransactions={[row]}
            isOpen={true}
            onClosed={() => setOpenPaymentModal(false)}
            disableCreateCredit={true}
            isRouted={false}
            onSuccess={(item) => {
              rootCallback({
                event: RootCallbackEvent.PaymentOnSuccess,
                item,
              });
            }}
            patientId={row?.patient?.id || ''}
          />
        )}
      </>
    );
  }

  return (
    <>
      <div
        className={classNames('flex flex-row space-x-8', ClassNames.bg, {
          'pb-28': isLast && currentPhase.name === 'Edit',
        })}
        ref={myRef}
        id={`slot-${row?.billingKey}`}
      >
        <div className="flex w-8 justify-end">
          <ul className="space-y-1 flex flex-col">{nav.map((item) => item)}</ul>
        </div>
        <div
          className={classNames(
            'rounded-lg border x-overflow-hidden grow border-gray-300 dark:border-darkGray-600 pt-0 px-4 pb-4',
            thisSectionClassName,
          )}
        >
          <div className="relative">
            <TransactionEditorTabs
              setActiveTabName={setActiveTabName}
              switches={{
                Activity: !editingNewTransaction,
                Invoices: !editingNewTransaction,
                Detail: !editingNewTransaction,
              }}
              callbacks={{
                onFromDetail: (e: any, tab: any) => {
                  if (isNotReadOnly) {
                    rootCallback?.({
                      event: RootCallbackEvent.AddMessageItem,
                      item: {
                        id: 'move-tab-while-editing',
                        title: 'Please cancel editing before changing tabs.',
                      },
                    });
                    return false;
                  }
                },
              }}
            />
            {clipboardIcon}
          </div>

          <div>
            {editingNew ? (
              <div>... new transactions not supported in this view ...</div>
            ) : (
              <div className="relative">
                <div className="p-4">
                  <ButtonGroup
                    buttons={buttonGroupButtons}
                    disabled={isFetching || isSaving || !row}
                    isEmptyOkay={true}
                  />
                </div>

                <div className="p-4">
                  {isFetching && !isSaving ? (
                    <div className="flex justify-center p-4">
                      <Loading color="text-gray-400" />
                    </div>
                  ) : (
                    <>
                      {/* Shut the hell up empty <></> error! */}
                      <TransactionEditorTabContent
                        activeSubtype={activeSubtype}
                        activeTabName={activeTabName}
                        editing={currentPhase.name === 'Edit'}
                        isLoading={isFetching}
                        noCodesMessage="No codes are associated with this purchase."
                        policies={policies}
                        readonly={isLocalReadOnly}
                        setActiveSubtype={setActiveSubtype}
                        rootCallback={rootCallback}
                        workingTransaction={row}
                        saveServicesCallback={() => {
                          setCurrentPhase(phases[currentPhase.next]);
                        }}
                        patientPackages={patientPackages}
                        triggerAppendItems={triggerAppendItems}
                        addPendingItems={addPendingItems}
                        findThePackageCredit={findThePackageCredit}
                        insurancesToUse={insurancesToUse}
                        responsibilities={responsibilities}
                        payorResponsibilityTotal={payorResponsibilityTotal}
                        setTransactionManually={setTransactionManually}
                        patientInsuranceResponsibility={
                          patientInsuranceResponsibility
                        }
                        patientResponsibility={patientResponsibility}
                        balanceAllocatedToPatient={balanceAllocatedToPatient}
                        instanceComponents={{
                          lockButton: isLockAvailable ? (
                            <LockButtonContent
                              state={{
                                available: isLockAvailable,
                                locked: isTransactionLocked,
                              }}
                              transaction={row}
                              readonly={isLocalReadOnly}
                              isBillingStarted={row?.isBillingStarted}
                              onClick={(e: any) => {
                                actionsOnClick(ActionsOnClick.Lock, row);
                              }}
                            />
                          ) : null,
                          unMergeButton:
                            hasPayments ||
                            showDueToTrace ||
                            !mergeContext?.into?.[
                              row?.billingKey ?? ''
                            ] ? null : (
                              <UnMergeButtonContent
                                transaction={row}
                                state={{
                                  available: isLockAvailable,
                                  locked: isTransactionLocked,
                                }}
                                isBillingStarted={row?.isBillingStarted}
                                readonly={isLocalReadOnly}
                                onClick={(e: any) => {
                                  e?.stopPropagation?.();
                                  e?.preventDefault?.();
                                  actionsOnClick(ActionsOnClick.UnMerge, row);
                                }}
                              />
                            ),
                        }}
                        actionButtonsComponent={actionButtonsComponent}
                        alertBlockComponent={
                          <AlertBlock
                            level="none"
                            message={invoiceTestingMessage}
                            closeCallback={() => {
                              setTransactionManually({
                                ...row,
                                invoiceTesting: undefined,
                              } as any);
                            }}
                          />
                        }
                      />
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
          {row && openPaymentModal && (
            <Payment
              passedTransactions={[row]}
              isOpen={true}
              onClosed={() => setOpenPaymentModal(false)}
              disableCreateCredit={true}
              isRouted={false}
              onSuccess={(payment) => {
                const transactionPayments = payment.items || [];
                const paymentHash = transactionPayments.reduce(
                  (obj: any, p: any) => ({
                    ...obj,
                    [p.paymentToward]: p,
                  }),
                  {} as Record<string, any>,
                );

                const paymentReturned = paymentHash[row.billingKey];
                if (paymentReturned) {
                  const newItems = row.items || [];
                  newItems.push({
                    id: paymentReturned.id,
                    amount: paymentReturned.amount,
                    createdAt: paymentReturned.createdAt,
                    description: paymentReturned.description,
                    payorName: paymentReturned.payorName,
                    patientTransactionId: paymentReturned.patientTransactionId,
                    subtype: TransactionItemSubtypeEnum.Payment,
                    type: TransactionItemTypeEnum.Credit,
                    units: paymentReturned.units,
                  });
                  setTransactionManually({
                    ...row,
                    items: newItems,
                  });
                }
              }}
              patientId={row?.patient?.id || ''}
            />
          )}
          <MakeBrowserWait isWaiting={isFetching || isSaving} />
          {row?.billingKey && currentPhase.name !== 'Edit' && (
            <>
              {showAllocacteNonBillableModal ? (
                <AllocateNonBillableToPatientModal
                  isOpen={true}
                  close={() => setShowAllocateNonBillableModal(false)}
                  amount={allocatableBalance}
                  billingKey={row?.billingKey}
                  refetch={refetch}
                  patientId={row?.patient?.id || ''}
                  isAllocating={isAllocating}
                  setIsAllocating={setIsAllocating}
                />
              ) : null}
              {showWriteOffNonBillableModal ? (
                <WriteOffNonBillableBalanceModal
                  isOpen={true}
                  close={() => setShowWriteOffNonBillableModal(false)}
                  amount={allocatableBalance}
                  billingKey={row?.billingKey}
                  refetch={refetch}
                  patientId={row?.patient?.id || ''}
                  isProcessingWriteOff={isWritingOff}
                  setIsProcessingWriteOff={setIsWritingOff}
                />
              ) : null}
            </>
          )}

          <ChoosePackageModal
            isOpen={isChoosePackageModal}
            close={() => setIsChoosePackageModal(false)}
            applyPackage={applyPackage}
            canApplyPackages={canApplyPackage}
            packages={patientPackages?.data?.packages}
          />

          {trace ? (
            <div className="mt-4">
              <Button
                onClick={() => setTraceOpen(!traceOpen)}
                text="Toggle #2"
                color={ButtonColors.plainWithBorder}
              />
              <div className={classNames('p-4', traceOpen ? '' : 'hidden')}>
                {/* <pre>{ChiroUpJSON.pretty(localRow)}</pre> */}
                <h1>localRow</h1>
                <pre>
                  {ChiroUpJSON.pretty({
                    buttonStuff: {
                      isAnyRestRunning,
                      currentPhase,
                      row: !!row,
                      isFetching,
                      isSaving,
                      isTransactionLocked,
                      discountAlreadyApplied,
                      isLockAvailable,
                    },
                    id: row?.id,
                    billingKey: row?.billingKey,
                    provider: row?.provider,
                    type: row?.type ? row.type : '- no type -',
                    merged: row?.merged,
                    hasPayments,
                    encounterContext,
                    isTransactionLocked,
                    services: row?.services,
                    items: row?.items,
                  })}
                </pre>
                <hr />
                <h1>services</h1>
                <pre>{ChiroUpJSON.pretty({ services: row?.services })}</pre>
                <hr />
                <h1>items</h1>
                <pre>{ChiroUpJSON.pretty({ items: row?.items })}</pre>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {showDiscountModal ? (
        <DiscountModal
          patientId={row?.patient?.id || ''}
          transaction={row}
          addPendingItems={addPendingItems}
          isOpen={true}
          isRouted={false}
          {...MODALS.DiscountModal}
        />
      ) : null}
      {showSupplementsModal ? (
        <SupplementsModal
          patientId={row?.patient?.id || ''}
          transaction={row}
          addPendingItems={addPendingItems}
          isOpen={true}
          isRouted={false}
          onClose={() => setShowSupplementsModal(false)}
        />
      ) : null}
      {isWriteOffClaimsModalOpen ? (
        <>
          {/* <h2>Hi</h2> */}
          <WriteOffClaimsModal
            billingKey={row?.billingKey}
            isNew={editingNew}
            patientResponsibility={createDecimal(patientResponsibility)}
            callbacks={{
              onClose: () => setIsWriteOffClaimsModalOpen(false),
              onSuccess: () => {
                refetch();
                setBackgroundDirty(true);
              },
            }}
          />
        </>
      ) : null}
      {/* {isBlahOpen ? (
        <>
          <pre>{ChiroUpJSON.pretty({ clinicId: row?.clinicId, userTz })}</pre>
          <ClaimResponsePostModal
            clinicId={row?.clinicId}
            claimResponseId={-1}
            userTz={userTz}
            isOpen={true}
            close={() => {
              console.log('Ouch...that hurts!!!');
              setIsBlahOpen(false);
              // setPostClaimModalOpen(false);
              // setBillingKey('');
              // setPostClaimResponseId(undefined);
            }}
            refetch={refetch}
            billingKey={row?.billingKey}
            era={{} as any} // TODO: Fix this type
          />
        </>
      ) : null} */}
    </>
  );
};

export default SingleTransaction;
