import {
  AlertBlock,
  Autocomplete,
  ConfirmModal,
  MakeBrowserWait,
  NotFound,
  OpenClosedStates,
  Select,
  SelectOption,
  Textarea,
  TimestampDisplay,
  TrivialTooltip,
  getNearest,
  getNearestTime,
} from '@chiroup/components';
import { FeatureFlags } from '@chiroup/core/constants/flags';
import {
  NUMBER_ANY_HASH,
  SELECT_OPTION_TYPE,
  STRING_BOOLEAN_HASH,
} from '@chiroup/core/constants/globals';
import {
  ChiroUpAppointmentCommon,
  ChiroUpDayJsCommon,
} from '@chiroup/core/constants/stringConstants';
import { surveyIds } from '@chiroup/core/constants/SurveyIds';
import { CommunicationMethodTypes } from '@chiroup/core/enums/CommunicationMethodTypes.enum';
import { ChiroUpJSON } from '@chiroup/core/functions/ChiroUpJSON';
import { classNames } from '@chiroup/core/functions/classNames';
import { durationDisplay } from '@chiroup/core/functions/durationDisplay';
import businessRulesService from '@chiroup/core/services/businessRules.service';
import {
  AppointmentForUI,
  AppointmentStatuses,
  disableStatusesInPast,
} from '@chiroup/core/types/Appointment.type';
import { FormErrors } from '@chiroup/core/types/ErrorResponse.type';
import { Patient } from '@chiroup/core/types/Patient.type';
import { RoomStatus } from '@chiroup/core/types/RoomStatus.type';
import { SubmitReturnType } from '@chiroup/hooks';
import { Disclosure } from '@headlessui/react';
import {
  QuestionMarkCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import qs from 'query-string';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useQueryClient } from 'react-query';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Datepicker from 'react-tailwindcss-datepicker';
import { MeContext } from '../../contexts/me.context';
import useSurveys from '../../hooks/useSurveys';
import Button, { ButtonColors } from '../common/Button';
import DisclosureButton from '../common/DisclosureButton';
import IconButton from '../common/IconButton';
import LegacyEhrUser from '../common/icons/LegacyEhrUser';
import UploadedImage from '../common/uploader/UploadedImage';
import PatientCreateModal from '../patients/create/PatientCreateModal';
import usePatients from '../patients/hooks/usePatients';
import useConsents from '../practice-resources/hooks/useConsents';
import { transformPreferences } from '../settings/clinic/ClinicSettingsPatientPreferences';
import { PatientPreferencesForm } from '../settings/clinic/ClinicSettingsPatientPreferencesForm';
import ScheduleAppointmentFormSlot from './ScheduleAppointmentFormSlot';

const maxSlots = 7;

type Props = {
  appointment?: AppointmentForUI | null;
  onClose: () => void;
  saveAppointment: ({
    appointment,
    notify,
    removeRecurringId,
    doubleBook,
  }: {
    appointment: Partial<AppointmentForUI>;
    notify?: boolean;
    removeRecurringId?: boolean;
    doubleBook?: boolean;
  }) => Promise<AppointmentForUI | undefined>;
  timezone: string;
  newAppointment?: boolean;
  disabled?: boolean;
  editingOneOfRecurring?: boolean;
  setEditType?: (type: string) => void;
  setShowForm?: (show: boolean) => void;
  value: Partial<AppointmentForUI>;
  onChange: (key: keyof AppointmentForUI) => (val: any) => void;
  errors: FormErrors;
  isDirty: boolean;
  isSubmitting: boolean;
  setValue: (values: Partial<AppointmentForUI>) => void;
  registerSubmit: (
    fnSubmit: (
      values: Partial<AppointmentForUI>,
    ) => SubmitReturnType<AppointmentForUI, void> | undefined,
    {
      onSuccess,
      onFail,
    }: {
      onSuccess?: ((response: any) => void) | undefined;
      onFail?: ((errors: any) => void) | undefined;
      options?: {
        throwOnNoResult?: boolean;
      };
    },
  ) => (e?: any) => void;
  patchValue: (values: Partial<AppointmentForUI>) => void;
  patient?: Patient;
  treatmentHash: any;
  disciplineOptions: SELECT_OPTION_TYPE[];
  dontNavigate?: boolean;
  addNewSlot: () => void;
  deleteMySlot: (slot: number, options: any) => void;
  queryParams?: any;
  updateMySlot: ({
    ord,
    key,
    value,
  }: {
    ord: number;
    key: string;
    value: any;
  }) => void;
  markAsDirty: () => void;
  formEditState?: boolean | null;
  formEditStateCallback?: (() => void) | null;
  doubleBookErrorMessage?: RoomStatus[];
  setDoubleBookErrorMessage?: React.Dispatch<
    React.SetStateAction<RoomStatus[]>
  >;
  disableButtonGroup?: boolean;
  trackOptions: SelectOption[];
  trace?: boolean;
  setButtons: React.Dispatch<React.SetStateAction<JSX.Element[]>>;
  isAdding?: boolean;
  trackHashSendSurveys?: { [key: string]: boolean };
};

const ScheduleAppointmentForm: React.FC<Props> = ({
  appointment,
  onClose,
  saveAppointment,
  timezone,
  newAppointment = false,
  disabled = false,
  editingOneOfRecurring = false,
  setEditType,
  setShowForm,
  onChange,
  errors,
  value,
  isDirty,
  isSubmitting,
  setValue,
  registerSubmit,
  patchValue,
  patient,
  treatmentHash,
  disciplineOptions,
  dontNavigate = false,
  addNewSlot,
  updateMySlot,
  deleteMySlot,
  queryParams = {},
  markAsDirty,
  formEditState,
  formEditStateCallback,
  doubleBookErrorMessage,
  setDoubleBookErrorMessage,
  disableButtonGroup = false,
  trackOptions,
  trace = false,
  setButtons,
  isAdding,
  trackHashSendSurveys,
}) => {
  const [newPatientModalState, setNewPatientModalState] =
      useState<OpenClosedStates>(OpenClosedStates.Closed),
    queryClient = useQueryClient();
  const [localErrors, setLocalErrors] = useState<string>('');
  const [isEditing, setIsEditing] = useState(false);
  const [doubleBooking, setDoubleBooking] = useState(false);
  const { me, hasAccess, selectedLocationFull } = useContext(MeContext);
  const [businessRuleIssues, setBusinessRuleIssues] = useState<any[]>([]);
  const location = useLocation();
  const { search } = location;
  const [okayToSave] = useState(true);
  const [cancelClone, setCancelClone] = useState<AppointmentForUI>(
    {} as AppointmentForUI,
  );
  const { data: surveys } = useSurveys();
  const { data: consents } = useConsents();
  const navigate = useNavigate();
  const [requestInfoItems, setRequestInfoItems] = useState<any[]>();
  const [createdNewPatient, setCreatedNewPatient] = useState(false);

  useEffect(() => {
    if (typeof formEditState === 'boolean' && !!formEditStateCallback) {
      setIsEditing(formEditState);
      formEditStateCallback();
    }
  }, [formEditState, formEditStateCallback]);

  const newPatientOpen = () => {
    setNewPatientModalState(OpenClosedStates.Open);
  };

  const newPatientClose = (patient: any) => {
    setNewPatientModalState(OpenClosedStates.Closed);
    if (patient) {
      queryClient.setQueryData(['patients', searchQuery], (old: any) => {
        return {
          ...old,
          pages: old.pages.map((page: any) => {
            return {
              ...page,
              data: [...page.data, patient],
            };
          }),
        };
      });
      patchValue({ patientId: patient.ID });
      setCreatedNewPatient(true);
    }
  };

  const {
    data,
    isFetching: isFetchingPatients,
    fetchNextPage,
    hasNextPage,
    onSearch,
    searchQuery,
    setSearchQuery,
  } = usePatients({ showDeceased: false, skipEmptySearch: true });

  useEffect(() => {
    return () => {
      setCreatedNewPatient(false);
    };
  }, []);

  useEffect(() => {
    if (appointment) {
      patchValue(appointment);
    } else {
      const {
        treatment,
        clinician,
        startTime,
        startDate,
        duration,
        patient,
        patientName,
        slots,
        room,
      } = queryParams;

      if (
        !treatment &&
        !clinician &&
        !startTime &&
        !duration &&
        !slots &&
        !patient &&
        !patientName &&
        !room
      ) {
        return;
      }
      const decodedSlots = JSON.parse(decodeURIComponent(slots || '[]'));
      const formattedSlots = decodedSlots?.map((slot: any) => {
        return {
          duration: slot?.duration || 15,
          clinicianId: slot?.clinicianId || '',
          treatmentId: slot.treatmentId || -1,
          startTime: slot.startTime,
          roomId: room ?? slot.roomId ?? '',
        };
      });
      console.log({ formattedSlots });

      const apptSettings = me?.selectedClinic?.settings?.find(
        (setting) => setting.setting === 'Appointment Settings',
      );
      if (patientName && patientName?.length) {
        setSearchQuery(patientName);
      }
      patchValue({
        patientId: patient,
        treatmentId: treatment
          ? Number(treatment)
          : apptSettings?.jsonValue?.appointmentDefaultTreatment,
        clinicianId: clinician,
        startTime: dayjs.tz(
          startTime
            ? Number(startTime)
            : startDate
              ? getNearestTime(startDate)
              : getNearest(timezone),
          timezone,
        ),
        roomId: room,
        duration: duration
          ? Number(duration)
          : apptSettings?.jsonValue?.appointmentDefaultDuration,
      });
      if (formattedSlots?.length) {
        patchValue({ slots: formattedSlots });
      }
    }
  }, [
    appointment,
    patchValue,
    queryParams,
    me?.selectedClinic?.settings,
    timezone,
    setSearchQuery,
    selectedLocationFull.timezone,
  ]);

  useEffect(() => {
    if (patient && !appointment?.id) {
      if (!queryParams?.clinician && patient?.primaryClinician) {
        patchValue({
          clinicianId: patient?.primaryClinician,
        });
      }
    }
  }, [
    patient,
    patchValue,
    setSearchQuery,
    queryParams?.clinician,
    appointment?.id,
  ]);

  useEffect(() => {
    if (patient) {
      setSearchQuery(`${patient?.lname}, ${patient?.fname}`);
      patchValue({
        patientId: patient?.ID,
      });
    }
  }, [patient, patchValue, setSearchQuery, search]);

  const patientInfo = useMemo(() => {
    const patientInfoArr = [
      { name: 'Basic intake group', group: 'Groups', id: 'basicIntakeGroup' },
      {
        name: 'Detailed intake group',
        group: 'Groups',
        id: 'detailedIntakeGroup',
      },
      {
        name: 'Photo ID',
        group: 'Intake & history surveys',
        id: 'requestPhotoId',
      },
      {
        name: 'Patient demographics',
        group: 'Intake & history surveys',
        id: 'requestInfo',
      },
    ];

    if (hasAccess(FeatureFlags.billingInsurance)) {
      patientInfoArr.push({
        name: 'Insurance',
        group: 'Intake & history surveys',
        id: 'requestInsurance',
      });
    } else {
      patientInfoArr.push({
        name: 'Insurance card',
        group: 'Intake & history surveys',
        id: 'requestInsuranceCard',
      });
    }

    return patientInfoArr;
  }, [hasAccess]);

  useEffect(() => {
    if (surveys && !requestInfoItems?.length) {
      const hiddenSurveys: string[] =
        me?.selectedClinic?.settings?.find(
          (clinic) => clinic.setting === 'Hidden surveys',
        )?.jsonValue || [];
      //TODO Maybe put CCS back in, might work as long as we do what Todd says below
      //CCS logic will be fixe in future story.
      //Also, we need to make sure the appointment group id is on the surveys that are sent.
      hiddenSurveys.push(surveyIds.ccs);
      const filteredData: {
        name: string;
        group: string;
        id: string;
      }[] =
        surveys?.data?.filter((item) => !hiddenSurveys?.includes(item.id)) ||
        [];

      let items = [...(patientInfo || []), ...(filteredData || [])];
      if (consents?.length) {
        items = [
          ...items,
          ...(consents?.map((consent) => ({
            name: consent.name,
            group: 'Consents & Acknowledgement surveys',
            id: `consent::${consent.url}`,
          })) || []),
        ];
      }
      items.sort((a, b) => {
        if (a.group === 'Groups' && b.group !== 'Groups') {
          return -1;
        } else if (a.group !== 'Groups' && b.group === 'Groups') {
          return 1;
        }
        const groupComparison = a.group.localeCompare(b.group);
        if (groupComparison !== 0) {
          return groupComparison;
        }
        return a.name.localeCompare(b.name);
      });
      setRequestInfoItems(items);
    }
  }, [
    surveys,
    consents,
    me?.selectedClinic?.settings,
    patientInfo,
    requestInfoItems,
  ]);

  const flatPatients = useMemo(() => {
    return data?.pages?.map((page) => page?.data).flat() || [];
  }, [data]);

  const [fullPatient, setFullPatient] = useState<Patient | null>(null);

  const patientOptions = useMemo(() => {
    return flatPatients?.map((pt) => ({
      text: pt && (
        <div className="flex">
          <div className="flex flex-col leading-4">
            <span>
              {pt.lname}, {pt.fname}
            </span>
            <span className="text-xs opacity-50">
              {pt.dateOfBirth
                ? ` ${dayjs(pt.dateOfBirth).format('MM/DD/YYYY')}`
                : ''}
            </span>
          </div>
          {!!pt?.legacyEHRId && <LegacyEhrUser />}
        </div>
      ),
      value: pt.ID,
    }));
  }, [flatPatients]);

  const clearBusinessRuleIssues = useCallback(() => {
    setBusinessRuleIssues([]);
  }, [setBusinessRuleIssues]);

  const primaryDisciplineOptions = useMemo(() => {
    const noIdAndOldAppt =
      !value?.id && queryParams?.open !== 'add' && !isAdding;
    if (noIdAndOldAppt) return [];
    if (!value?.slots) return [];
    const usedTreatmentIds = value.slots.map((slot) => {
      return slot.treatmentId;
    }, [] as number[]);

    const opts = disciplineOptions?.filter((option: any) => {
      let res = false;
      let x = 0;
      while (x < usedTreatmentIds.length && !res) {
        if (option.treatments[usedTreatmentIds[x]]) res = true;
        x++;
      }
      return res;
    });
    return opts;
  }, [disciplineOptions, value?.slots, queryParams?.open, value?.id, isAdding]);

  const onSubmit = useCallback(
    async (values: Partial<AppointmentForUI>, doubleBook?: boolean) => {
      setBusinessRuleIssues([]);
      if (disabled) return;
      const issues = businessRulesService.sanityCheckAppointment({
        appointment: value as AppointmentForUI,
        primaryDisciplineOptions,
        tz: selectedLocationFull?.timezone,
        frontend: true,
        patientHasTracks: !!trackOptions?.length,
      });

      if (issues.length) {
        setBusinessRuleIssues(issues);
        setTimeout(() => {
          document?.getElementById?.('alertRef')?.scrollIntoView({
            behavior: 'smooth',
          });
        }, 100);
        return;
      }

      if (
        value.policies?.length &&
        !value.policies?.every((policy) => policy.billingPriority)
      ) {
        return;
      }
      if (appointment?.recurringAppointmentId) {
        return saveAppointment({
          appointment: values,
          removeRecurringId: true,
        });
      }

      return saveAppointment({
        appointment: values,
        doubleBook,
      })
        .then((resp: AppointmentForUI | undefined) => {
          // console.log({ saveOnAppointment: resp });
          if (resp && resp?.id) {
            patchValue(resp as AppointmentForUI);
          }
          return resp;
        })
        .catch((e: any) => {
          console.error(e);
          setLocalErrors(e?.message || 'Error saving appointment');
          throw e;
        });
    },
    [
      value,
      disabled,
      appointment?.recurringAppointmentId,
      patchValue,
      saveAppointment,
      selectedLocationFull?.timezone,
      trackOptions?.length,
      primaryDisciplineOptions,
    ],
  );

  const onSubmitNotify = useCallback(
    async (values: Partial<AppointmentForUI>) => {
      if (disabled) return;
      return saveAppointment({
        appointment: values,
        notify: true,
      });
    },
    [disabled, saveAppointment],
  );

  const onSuccess = useCallback(
    (val: any) => {
      if (trace) console.log('......on-success is firing...', val);
      setIsEditing(false);
      const searchParams = qs.parse(search);
      if (newAppointment) {
        if (val.id) {
          const params =
            searchParams.startDate && searchParams.endDate
              ? `/schedule?open=${val.id}&startDate=${searchParams.startDate}&endDate=${searchParams.endDate}`
              : `/schedule?open=${val.id}`;
          if (!dontNavigate) {
            navigate(`${params}`);
          } else {
            //Coming from appointment tab in patient
            onClose();
          }
        }
      }
    },
    [trace, search, newAppointment, dontNavigate, onClose, navigate],
  );

  const onFail = (e: any) => {
    console.log('......on-fail is firing...', e);
    //  Nothing is done here as there is another catch that handles errors.
  };

  const disabledEvent = useMemo(() => {
    if (!appointment) return false;
    if (value?.id !== 'add') return false;
    const inPast = dayjs(appointment.startTime) < dayjs();
    const disabledEvent =
      !!appointment.recurringAppointmentId ||
      (inPast && disableStatusesInPast.includes(appointment.status));
    return disabledEvent;
  }, [appointment, value?.id]);

  // So all new ones will have requestInfo but older ones may say demographics
  const customIntakes = useMemo(() => {
    const customSettings = me.selectedClinic?.settings?.find(
      (setting) => setting.setting === 'Custom intakes',
    );

    const basicIntakeGroup = customSettings?.jsonValue?.basicIntakeSurveys?.map(
      (survey: string) => (survey === 'demographics' ? 'requestInfo' : survey),
    );
    const detailedIntakeGroup =
      customSettings?.jsonValue?.detailedIntakeSurveys?.map((survey: string) =>
        survey === 'demographics' ? 'requestInfo' : survey,
      );

    return {
      basicIntakeGroup,
      detailedIntakeGroup,
    };
  }, [me.selectedClinic?.settings]);

  const onChangeSurveys = useCallback(
    (val: string[]) => {
      if (val?.includes('basicIntakeGroup')) {
        if (customIntakes?.basicIntakeGroup) {
          val = val.concat(customIntakes.basicIntakeGroup);
        } else {
          val = val.concat([
            surveyIds.healthHistory,
            surveyIds.reviewOfSystems,
          ]);
        }
      }
      if (val?.includes('detailedIntakeGroup')) {
        if (customIntakes?.detailedIntakeGroup) {
          val = val.concat(customIntakes.detailedIntakeGroup);
        } else {
          val = val.concat([
            surveyIds.healthHistory,
            surveyIds.reviewOfSystems,
            surveyIds.lifestyleHistory,
            surveyIds.familyHistory,
            surveyIds.medicationHistory,
            surveyIds.surgicalHistory,
            'requestInfo',
          ]);
        }
      }
      val = val.filter(
        (surveyId) =>
          surveyId !== 'basicIntakeGroup' && surveyId !== 'detailedIntakeGroup',
      );
      // Remove duplicates
      val = Array.from(new Set(val));
      const availableSurveyIds =
        requestInfoItems?.map((survey) => survey.id) ?? [];
      // Filter val to include only available survey IDs
      val = val.filter((surveyId) => availableSurveyIds.includes(surveyId));

      onChange('surveys')(val);
    },
    [customIntakes, onChange, requestInfoItems],
  );

  const surveyOptions = useMemo(() => {
    return requestInfoItems?.map((survey) => ({
      text: survey.name,
      value: survey.id,
      group: survey.group,
    }));
  }, [requestInfoItems]);

  const patientPreferencesTranslations: { [key: string]: string } =
    useMemo(() => {
      return {
        allergySurvey: surveyIds.allergy,
        'Back Bournemouth Questionnaire': surveyIds.backBournemouth,
        lefs: surveyIds.lefs,
        'Neck Bournemouth Questionnaire': surveyIds.neckBournemouth,
        ndi: surveyIds.ndi,
        quickDash: surveyIds.dash,
        oswestry: surveyIds.oswestry,
        ccs: surveyIds.ccs,
        familyHistory: surveyIds.familyHistory,
        healthHistory: surveyIds.healthHistory,
        lifestyleHistory: surveyIds.lifestyleHistory,
        medicationHistory: surveyIds.medicationHistory,
        reviewOfSystems: surveyIds.reviewOfSystems,
        surgicalHistory: surveyIds.surgicalHistory,
        photoID: 'requestPhotoId',
        insurance: hasAccess(FeatureFlags.billingInsurance)
          ? 'requestInsurance'
          : 'requestInsuranceCard',
        newInsurance: 'requestInsurance',
        demographics: 'requestInfo',
      };
    }, [hasAccess]);

  useEffect(() => {
    if (me?.selectedClinic?.settings && createdNewPatient) {
      // console.log({
      //   settings: me.selectedClinic?.settings,
      //   patientPreferencesTranslations,
      // });
      const transformedSettings = Array.isArray(me.selectedClinic?.settings)
        ? transformPreferences(me.selectedClinic?.settings)
        : me.selectedClinic?.settings || {};
      // console.log({ transformedSettings });
      const newPatientVals = Object.keys(patientPreferencesTranslations).reduce(
        (
          obj: {
            ccsFollowups?: string[];
            surveys?: string[];
            prefers?: CommunicationMethodTypes;
            requestInfo?: boolean;
            requestInsuranceCard?: boolean;
            requestInsurance?: boolean;
            requestPhotoId?: boolean;
          },
          setting: any,
        ) => {
          if (
            ['demographics', 'photoID', 'insurance'].includes(setting) &&
            transformedSettings[setting as keyof PatientPreferencesForm]
          ) {
            obj.surveys?.push(patientPreferencesTranslations[setting]);
          } else if (
            transformedSettings[setting as keyof PatientPreferencesForm]
          ) {
            const translation = patientPreferencesTranslations[setting];
            if (
              setting === 'ndi' ||
              setting === 'lefs' ||
              setting === 'quickDash' ||
              setting === 'oswestry'
            ) {
              obj.ccsFollowups?.push(translation);
            } else if (
              (translation && !hasAccess(FeatureFlags.ehr)) ||
              (translation &&
                translation !== surveyIds.ccs &&
                hasAccess(FeatureFlags.ehr))
            ) {
              obj.surveys?.push(translation);
            }
          }
          return obj;
        },
        { surveys: [] },
      );

      patchValue(newPatientVals);
    }
  }, [
    hasAccess,
    me.selectedClinic?.settings,
    patchValue,
    patientPreferencesTranslations,
    createdNewPatient,
  ]);

  const slotOptions = useMemo(() => {
    const clinicians =
      me.selectedClinic?.clinicians?.map((clinician) => {
        return { text: clinician.name, value: clinician.ID };
      }) ?? [];

    if ((value?.slots?.length || 0) > 1) {
      clinicians.unshift({ text: '- primary -', value: '-1' });
    }

    return {
      clinicians,
      treatments: treatmentHash?.options as SELECT_OPTION_TYPE[],
      durations:
        treatmentHash?.durations?.map((duration: number) => ({
          value: duration,
          text: String(duration),
        })) || [],
      rooms: treatmentHash?.roomSelectComponents || [],
    };
  }, [
    me.selectedClinic?.clinicians,
    treatmentHash?.durations,
    treatmentHash?.options,
    treatmentHash?.roomSelectComponents,
    value?.slots?.length,
  ]);

  const [slotContext, setSlotContext] = useState<NUMBER_ANY_HASH>({
    0: { visible: true },
  });

  useEffect(() => {
    if (editingOneOfRecurring) {
      setCancelClone(ChiroUpJSON.clone(appointment));
      setIsEditing(true);
    }
  }, [appointment, editingOneOfRecurring, slotContext, value, value.startTime]);

  const atSlotLimit = useMemo(() => {
    return value?.slots && value?.slots?.length >= maxSlots;
  }, [value?.slots]);

  const deleteMySlotAndUpdateContext = useCallback(
    (slot: number, options: any) => {
      // console.log({
      //   deleteMySlotAndUpdateContext: slot,
      //   there: typeof deleteMySlot,
      // });
      const arraySlots = Object.keys(slotContext).map(
        (key) => slotContext[Number(key)],
      );
      arraySlots.splice(slot, 1);
      const newContext = arraySlots.reduce((acc, curr, index) => {
        acc[index] = curr;
        return acc;
      }, {});
      setSlotContext(newContext);
      deleteMySlot(slot, options);

      // forceUpdate();
    },
    [deleteMySlot, slotContext],
  );

  const primaryClinicianOptions = useMemo(() => {
    if (!value?.slots) return [];
    const usedClinicians = value.slots.reduce((a, slot) => {
      a[slot.clinicianId] = true;
      return a;
    }, {} as STRING_BOOLEAN_HASH);
    return slotOptions?.clinicians.filter(
      (option) => usedClinicians[option.value] && option.value !== '-1',
    );
  }, [slotOptions?.clinicians, value.slots]);

  const primaryTreatmentOptions = useMemo(() => {
    if (!value?.slots) return [];
    const usedTreatmentIds = value.slots.reduce((a, slot) => {
      a[slot.treatmentId] = true;
      return a;
    }, {} as NUMBER_ANY_HASH);
    return slotOptions?.treatments?.filter(
      (option) =>
        usedTreatmentIds[Number(option.value)] && option.value !== '-1',
    );
  }, [slotOptions?.treatments, value.slots]);

  useEffect(() => {
    const noIdAndOldAppt =
      !value?.id && queryParams?.open !== 'add' && !isAdding;
    if (!primaryDisciplineOptions?.length || noIdAndOldAppt) return;
    const firstPrimaryDiscipline = primaryDisciplineOptions?.[0]?.value;
    const primaryIds = primaryDisciplineOptions?.map((option) => option.value);
    if (
      (!value?.disciplineId && firstPrimaryDiscipline) ||
      (value?.disciplineId && !primaryIds?.includes(value?.disciplineId as any))
    ) {
      onChange('disciplineId')(firstPrimaryDiscipline);
    }
  }, [
    primaryDisciplineOptions,
    onChange,
    value?.disciplineId,
    queryParams?.open,
    value?.id,
    isAdding,
  ]);
  const dateInPast = useMemo(() => {
    try {
      const dn = new Date(),
        intNow = new Date(
          `${dn.getFullYear()}-${dn.getMonth() + 1}-${dn.getDate()}`,
        ).getTime(),
        s = `${value?.startTime}`,
        intSelected = new Date(Number(s)).getTime();
      // console.log({ dateInPast: { s, intSelected, intNow, value } });
      return intSelected < intNow;
    } catch (e: any) {
      console.log(e);
      return false;
    }
  }, [value]);

  const [showTips, setShowTips] = useState<boolean>(false);

  const doubleBookConfirm = async () => {
    try {
      const searchParams = qs.parse(search);
      setDoubleBooking(true);
      const res = await saveAppointment({
        appointment: value || {},
        doubleBook: true,
      });

      const params =
        searchParams.startDate && searchParams.endDate
          ? `/schedule?open=${res?.id}&startDate=${searchParams.startDate}&endDate=${searchParams.endDate}`
          : `/schedule?open=${res?.id}`;
      navigate(`${params}`);
    } catch (e) {
      console.error(e);
    } finally {
      setDoubleBookErrorMessage?.([]);
      setDoubleBooking(false);
      setIsEditing(false);
    }
  };

  const firstProviderSlotIndex = useMemo(() => {
    return value?.slots?.reduce(
      (obj: { [key: string]: number }, slot, index) => {
        if (
          slot?.clinicianId &&
          obj[slot.clinicianId] !== 0 &&
          !obj[slot.clinicianId]
        ) {
          obj[slot?.clinicianId] = index;
        }
        return obj;
      },
      {},
    );
  }, [value?.slots]);

  useEffect(() => {
    const buttons = [];
    if (isEditing || newAppointment) {
      if (!newAppointment) {
        buttons.push(
          <Button
            key="cancel"
            text="Cancel"
            onClick={(event) => {
              setShowForm?.(false);
              setEditType?.('');
              setIsEditing(false);
              setSlotContext({});
              setValue(cancelClone);
              setBusinessRuleIssues([]);
              setLocalErrors('');
              event.stopPropagation();
              event.preventDefault();
            }}
            color={ButtonColors.plain}
          />,
        );
      }
      buttons.push(
        ...[
          <Button
            key="save"
            text="Save"
            disabled={
              !isDirty || isSubmitting || !okayToSave || !!localErrors?.length
            }
            loading={isSubmitting}
            type="submit"
            onClick={() => {
              registerSubmit(onSubmit, {
                onSuccess,
                onFail,
                options: {
                  throwOnNoResult: true,
                },
              })();
            }}
          />,
          <Button
            key="save-notify"
            text="Save & Notify"
            disabled={
              !isDirty || isSubmitting || !okayToSave || !!localErrors?.length
            }
            loading={isSubmitting}
            type="button"
            onClick={() => {
              registerSubmit(onSubmitNotify, {
                onSuccess,
                onFail,
                options: {
                  throwOnNoResult: true,
                },
              })();
            }}
          />,
        ],
      );
    }
    setButtons(buttons);

    return () => {
      setButtons([]);
    };
  }, [
    isEditing,
    newAppointment,
    cancelClone,
    registerSubmit,
    onSubmit,
    onSubmitNotify,
    setValue,
    isDirty,
    isSubmitting,
    localErrors?.length,
    okayToSave,
    onSuccess,
    setButtons,
    setEditType,
    setShowForm,
  ]);

  const primaryClinicianName = useMemo(() => {
    const empty = 'Primary';
    if (!value?.clinicianId) return empty;
    const pc = primaryClinicianOptions?.find(
      (i) => i.value === value?.clinicianId,
    );
    if (!pc) {
      const name = appointment?.displayValues?.clinicianName;
      if (name) return name + (isEditing ? ' (Primary)' : '');
      return empty;
    }
    return pc.text + (isEditing ? ' (Primary)' : '');
  }, [
    appointment?.displayValues?.clinicianName,
    isEditing,
    primaryClinicianOptions,
    value?.clinicianId,
  ]);

  const doubleBookRoomNames = useMemo(() => {
    const rooms = doubleBookErrorMessage?.reduce(
      (obj: { [key: string]: string }, err: any) => {
        obj[err.roomName] = '';
        return obj;
      },
      {},
    );
    return Object.keys(rooms || {}).join(', ');
  }, [doubleBookErrorMessage]);

  return (
    <div className="relative">
      {trace ? (
        <div className="bg-yellow-200 p-4">
          <pre>{ChiroUpJSON.pretty({ value })}</pre>
        </div>
      ) : null}
      <div
        className={classNames(
          'p-4 border border-gray-300 rounded-lg dark:border-darkGray-600 dark:bg-darkGray-900 dark:text-gray-300',
          disabled
            ? 'cursor-not-allowed'
            : disabledEvent
              ? 'cursor-not-allowed'
              : !isEditing
                ? 'cursor-pointer hover:bg-primary-50 hover:border-gray-300 dark:hover:bg-darkGray-800 '
                : ' ',
          disabledEvent ? 'bg-orange-50 ' : 'bg-gray-50',
        )}
        title={disabledEvent ? ChiroUpAppointmentCommon.cannotModify : ''}
        onClick={(e: any) => {
          e?.stopPropagation?.();
          e?.preventDefault?.();

          if (
            isEditing ||
            disabled ||
            disabledEvent ||
            appointment?.status === AppointmentStatuses.CheckedIn
          ) {
            return;
          }

          setCancelClone(ChiroUpJSON.clone(appointment));
          setIsEditing(true);
        }}
      >
        {isEditing || newAppointment ? (
          <form>
            {!appointment?.id && (
              <div className="flex flex-row space-x-4 h-24 align-top">
                {fullPatient?.profilePhoto ? (
                  <div className="flex">
                    <UploadedImage
                      url={fullPatient?.profilePhoto}
                      className="rounded-full h-16 w-16"
                    />{' '}
                  </div>
                ) : null}
                <div className="flex grow">
                  <Autocomplete
                    className="w-full"
                    name="patientId"
                    label="Patient *"
                    value={value.patientId}
                    placeholder="Type to search for a patient..."
                    onChange={(val) => {
                      const localFullPatient = flatPatients?.find(
                        (pt: Patient) => pt.ID === val,
                      );
                      if (localFullPatient && !queryParams?.clinician) {
                        patchValue({
                          patientId: val,
                          clinicianId: localFullPatient.primaryClinician,
                          slots: value?.slots?.map((slot) => ({
                            ...slot,
                            trackId: null,
                            clinicianId:
                              slot?.clinicianId ||
                              (localFullPatient.primaryClinician as string),
                          })),
                        });
                        if (localFullPatient.ID !== fullPatient?.ID) {
                          setFullPatient(localFullPatient);
                        }
                      } else if (localFullPatient && queryParams?.clinician) {
                        patchValue({
                          patientId: val,
                          slots: value?.slots?.map((slot) => ({
                            ...slot,
                            trackId: null,
                          })),
                        });
                        if (localFullPatient.ID !== fullPatient?.ID) {
                          setFullPatient(localFullPatient);
                        }
                      } else {
                        setFullPatient(null);
                      }
                    }}
                    options={patientOptions}
                    limit={1}
                    isFetching={isFetchingPatients}
                    fetchNextPage={fetchNextPage}
                    hasNextPage={hasNextPage}
                    onChangeSearch={onSearch}
                    searchTerm={searchQuery}
                    errors={errors.fieldErrors?.patientId}
                    addTitle="Create new patient..."
                    add={newPatientOpen}
                    autoFocus={!qs.parse(search)?.patient}
                    serverSide
                  />
                </div>
              </div>
            )}
            <div className="relative">
              {/* <label
                htmlFor="startDate"
                className="text-sm font-medium leading-5 text-gray-900 dark:text-darkGray-200 sm:mt-px sm:pt-2 flex flex-row justify-between"
              >
                <div></div>

              </label> */}
              {typeof value?.id === 'string' && value.id !== 'add' && (
                <>
                  <div className="flex flex-row justify-end w-full">
                    <div className="flex flex-col space-x-4">
                      <div
                        title="Click to see date-entry tips."
                        className="flex flex-row text-gray-400 hover:text-gray-600 space-x-1 w-14 cursor-pointer justify-end"
                        onClick={() => {
                          setShowTips(!showTips);
                        }}
                      >
                        <div className="w-5">
                          <QuestionMarkCircleIcon className="w-5 h-5" />
                        </div>
                      </div>
                    </div>
                  </div>
                  {showTips ? (
                    <div
                      id="tips-panel"
                      className="mt-6 border border-gray-300 p-4 rounded-lg  bg-white text-gray-600 relative"
                    >
                      <div className="absolute top-2 right-2">
                        <button
                          onClick={() => setShowTips(false)}
                          className="text-gray-400"
                        >
                          <XCircleIcon className="w-4 h-4" />
                        </button>
                      </div>
                      <ul className="list-disc text-sm font-sans px-6 py-4 space-y-2">
                        <li>
                          Click{' '}
                          <div
                            title="Plus icon."
                            className="w-5 inline-block text-primary-500"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.0"
                              stroke="currentColor"
                              // className="size-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                              />
                            </svg>
                          </div>{' '}
                          on the left below the number to add time slots.
                        </li>
                        <li>Only {maxSlots} may be added.</li>
                        <li>
                          When there is more than one slot the option to delete{' '}
                          <div className="inline-block text-primary-500">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-4 h-4"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                              />
                            </svg>
                          </div>{' '}
                          time slots is available.
                        </li>
                        <li>The last time stop may not be deleted.</li>
                        <li>
                          Clicking a time-slot number scrolls the screen to that
                          time slot.
                        </li>
                        <li>
                          A clinician or a room must be selected for each slot.
                        </li>
                        <li>
                          Providing a clinician or room makes the time slot show
                          up on the schedule for that clinician or room.
                        </li>
                        <li>No other fields are optional.</li>
                        <li>
                          Clicking{' '}
                          <span className="py-0.5 px-2 shadow-md no-underline rounded-full bg-primary-500 text-white font-sans text-xs border-blue btn-primary hover:text-white hover:bg-blue-light focus:outline-none active:shadow-none">
                            Custom
                          </span>{' '}
                          will allow entering a custom duration.
                        </li>
                        <li>
                          Clicking{' '}
                          <span className="py-0.5 px-2 shadow-md no-underline rounded-full bg-primary-500 text-white font-sans text-xs border-blue btn-primary hover:text-white hover:bg-blue-light focus:outline-none active:shadow-none">
                            Preset
                          </span>{' '}
                          switches back to the preset duration options.
                        </li>
                        <li>
                          Time slots must be in order by time-of-day and may not
                          overlap. Time slot #2's start time must be after slot
                          #1's start time plus its duration.
                        </li>
                      </ul>
                    </div>
                  ) : null}
                </>
              )}
              <div>
                <label className="block text-sm font-medium leading-5 text-gray-900 dark:text-darkGray-200 sm:mt-px sm:pt-2">
                  Date *
                  <TrivialTooltip
                    text={
                      dateInPast
                        ? 'The selected date is in the past.'
                        : undefined
                    }
                    iconColorClassName="text-orange-500"
                    iconSizeClassName="w-6 h-6"
                    type={`warn`}
                  />
                </label>
                <div className="relative date-picker-no-clear">
                  <Datepicker
                    value={
                      value?.startTime
                        ? {
                            startDate: dayjs(value.startTime).format(
                              'YYYY-MM-DD',
                            ),
                            endDate: dayjs(value.startTime).format(
                              'YYYY-MM-DD',
                            ),
                          }
                        : {
                            startDate: '',
                            endDate: '',
                          }
                    }
                    onChange={(val: any) => {
                      const clone = ChiroUpJSON.clone(
                        value,
                      ) as AppointmentForUI;
                      clone?.slots?.forEach((slot) => {
                        const time = dayjs(slot.startTime);
                        slot.startTime = dayjs(val.startDate)
                          .hour(time.hour())
                          .minute(time.minute())
                          .second(time.second())
                          .valueOf();
                      });
                      if ((clone as any)?.slots[0]?.startTime) {
                        clone.startTime = (clone as any)?.slots[0].startTime;
                      }
                      setValue(clone);
                    }}
                    asSingle
                    useRange={false}
                    placeholder="MM/DD/YYYY"
                    displayFormat="MM/DD/YYYY"
                    primaryColor="green"
                    inputClassName="w-full border border-gray-300 rounded-md shadow-sm pl-2 pr-10 mt-1 focus:outline-none focus:ring-none focus:ring-primary-500 focus:border-transparent dark:bg-darkGray-600 dark:text-darkGray-200"
                  />
                  <svg
                    className="pointer-events-none absolute top-1/2 right-3 h-5 w-5 text-gray-400 transform -translate-y-1/2"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 
                            2.25 0 012.25-2.25h13.5A2.25 2.25 0 
                            0121 7.5v11.25m-18 0A2.25 2.25 0 
                            005.25 21h13.5A2.25 2.25 0 
                            0021 18.75m-18 0v-7.5A2.25 
                            2.25 0 015.25 9h13.5A2.25 
                            2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 
                            15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 
                            15h.008v.008H9.75V15zm0 
                            2.25h.008v.008H9.75v-.008zM7.5 
                            15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 
                            2.25h.008v.008h-.008V15zm0 
                            2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 
                            2.25h.008v.008H16.5V15z"
                    />
                  </svg>
                </div>
              </div>
            </div>
            {!!newAppointment && (
              <Select
                name="surveys"
                className="col-span-2"
                label="Request info"
                value={value.surveys}
                onChange={onChangeSurveys}
                errors={errors.fieldErrors?.surveys}
                options={surveyOptions}
                autocomplete
                tooltip={ChiroUpAppointmentCommon.requestInfoTooltip}
                tooltipClassName="w-56"
                tooltipType="info"
              />
            )}
            <div className="flex flex-col space-x-2 justify-end">
              <div id="alertRef" className="w-full pt-6 pb-0">
                <AlertBlock
                  issues={businessRuleIssues}
                  closeCallback={clearBusinessRuleIssues}
                  // emptyComponent={<div>&nbsp;</div>}
                  margin={'mt-1 my-4'}
                  extraClassName=""
                  intro={<> </>}
                />
              </div>
            </div>
            <div className="flex flex-col space-y-8 mt-2">
              {value?.slots &&
                value.slots.length > 0 &&
                value.slots.map((slot, ord) => (
                  <ScheduleAppointmentFormSlot
                    key={`stop-${ord}`}
                    ord={ord}
                    slot={slot}
                    primaryStatus={appointment?.status}
                    onClose={onClose}
                    setValue={setValue}
                    timezone={selectedLocationFull?.timezone || ''}
                    options={slotOptions}
                    context={slotContext}
                    setContext={setSlotContext}
                    deleteMySlot={deleteMySlotAndUpdateContext}
                    location={selectedLocationFull}
                    updateMySlot={updateMySlot}
                    disableButtonGroup={disableButtonGroup}
                    patientId={value?.patientId}
                    firstProviderSlotIndex={firstProviderSlotIndex}
                    trackOptions={trackOptions}
                    treatmentHash={treatmentHash}
                    trackHashSendSurveys={trackHashSendSurveys}
                    addSlotButton={
                      <IconButton
                        tooltipPlacement="left"
                        icon={
                          !atSlotLimit ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.0"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                              />
                            </svg>
                          ) : (
                            <div></div>
                          )
                        }
                        onClick={(event: any) => {
                          if (atSlotLimit) return;
                          event.stopPropagation();
                          addNewSlot();
                          markAsDirty();
                        }}
                        disabled={atSlotLimit}
                        className={classNames(
                          'w-8 h-8',
                          atSlotLimit
                            ? 'text-gray-300 cursor-not-allowed'
                            : 'text-gray-400',
                        )}
                      />
                    }
                  />
                ))}
            </div>

            <Disclosure as="div" className="pt-8" defaultOpen={false}>
              {({ open }) => (
                <div className="bg-white dark:bg-darkGray-800 dark:border-darkGray-700 p-4 rounded-lg border border-gray-200">
                  <DisclosureButton open={open} title={primaryClinicianName} />
                  <Disclosure.Panel className="mt-4 w-full flex flex-col gap-2">
                    <fieldset className="flex flex-col gap-2 px-2">
                      <Select
                        name="clinicianId"
                        className="col-span-2"
                        label="Clinician"
                        value={value.clinicianId}
                        onChange={(e: any) => {
                          onChange('clinicianId')(e);
                        }}
                        errors={errors.fieldErrors?.clinicianId}
                        options={primaryClinicianOptions}
                        disabled={primaryClinicianOptions?.length === 1}
                        limit={1}
                        inlineSelect={true}
                        inlineLabelWidth="w-1/4"
                        inlineInputWidth="w-3/4"
                      />
                      <Select
                        name="treatmentId"
                        className="col-span-2"
                        label="Treatment"
                        value={value.treatmentId}
                        onChange={(e: any) => {
                          if (primaryDisciplineOptions?.length === 1) {
                            onChange('disciplineId')(
                              primaryDisciplineOptions[0]?.value,
                            );
                          }
                          onChange('treatmentId')(e);
                        }}
                        errors={errors.fieldErrors?.treatmentId}
                        options={primaryTreatmentOptions}
                        disabled={primaryTreatmentOptions?.length === 1}
                        limit={1}
                        inlineSelect={true}
                        inlineLabelWidth="w-1/4"
                        inlineInputWidth="w-3/4"
                      />
                      <Select
                        name="disciplineId"
                        className="col-span-2"
                        label="Discipline"
                        value={value.disciplineId}
                        onChange={(e: any) => {
                          onChange('disciplineId')(e);
                        }}
                        errors={errors.fieldErrors?.disciplineId}
                        options={primaryDisciplineOptions}
                        disabled={primaryDisciplineOptions?.length === 1}
                        limit={1}
                        inlineSelect={true}
                        inlineLabelWidth="w-1/4"
                        inlineInputWidth="w-3/4"
                      />
                    </fieldset>
                  </Disclosure.Panel>
                </div>
              )}
            </Disclosure>

            <fieldset className="mt-6 border rounded-lg p-4 bg-white dark:bg-darkGray-800 dark:border-darkGray-700">
              <div className="font-sans font-bold">Notes</div>
              <Textarea
                name="notes"
                label=" "
                value={value.notes}
                onChange={onChange('notes')}
                errors={errors.fieldErrors?.notes}
              />
            </fieldset>
            {/* <div className="flex-shrink-0 pl-4 py-5  mt-4">
              <div className="space-x-3 flex justify-end">
                {!newAppointment && (
                  <Button
                    text="Cancel"
                    onClick={(event) => {
                      setShowForm?.(false);
                      setEditType?.('');
                      setIsEditing(false);
                      setSlotContext({});
                      setValue(cancelClone);
                      setBusinessRuleIssues([]);
                      setLocalErrors('');
                      event.stopPropagation();
                      event.preventDefault();
                    }}
                    color={ButtonColors.plain}
                  />
                )}

                <Button
                  text="Save"
                  disabled={
                    !isDirty ||
                    isSubmitting ||
                    !okayToSave ||
                    !!localErrors?.length
                  }
                  loading={isSubmitting}
                  type="submit"
                  onClick={() => {
                    registerSubmit(onSubmit, {
                      onSuccess,
                      onFail,
                      options: {
                        throwOnNoResult: true,
                      },
                    })();
                  }}
                />
                <Button
                  text="Save & Notify"
                  disabled={
                    !isDirty ||
                    isSubmitting ||
                    !okayToSave ||
                    !!localErrors?.length
                  }
                  loading={isSubmitting}
                  type="button"
                  onClick={() => {
                    registerSubmit(onSubmitNotify, {
                      onSuccess,
                      onFail,
                      options: {
                        throwOnNoResult: true,
                      },
                    })();
                  }}
                />
              </div>
            </div> */}
          </form>
        ) : appointment ? (
          <div className="pb-5 pt-5 sm:pt-0">
            <dl className="space-y-8 sm:space-y-6">
              <div className="text-xl font-sans font-bold">
                <TimestampDisplay
                  ts={appointment?.startTime}
                  format={ChiroUpDayJsCommon.format.date}
                  errorValue={`- no date -`}
                />
              </div>

              <div>
                {value?.slots && value.slots.length > 0 ? (
                  value.slots.map((slot, ord) => (
                    <div key={`stop-${ord + 1}`} className="">
                      <ScheduleAppointmentFormSlot
                        key={`slot-${ord}`}
                        ord={ord}
                        onClose={onClose}
                        setValue={setValue}
                        primaryStatus={appointment?.status}
                        timezone={selectedLocationFull?.timezone || ''}
                        slot={slot}
                        trackOptions={trackOptions}
                        options={slotOptions}
                        disableButtonGroup={disableButtonGroup}
                        context={slotContext}
                        setContext={setSlotContext}
                        deleteMySlot={deleteMySlotAndUpdateContext}
                        location={selectedLocationFull}
                        updateMySlot={updateMySlot}
                        readonly={true}
                        patientId={value?.patientId}
                        firstProviderSlotIndex={firstProviderSlotIndex}
                        treatmentHash={treatmentHash}
                        trackHashSendSurveys={trackHashSendSurveys}
                      />
                    </div>
                  ))
                ) : (
                  <>
                    <div>
                      <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 dark:text-gray-300">
                        Clinician
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 dark:text-gray-300">
                        <p>{appointment?.displayValues?.clinicianName}</p>
                      </dd>
                    </div>
                    <div>
                      <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 dark:text-gray-300">
                        Treatment
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 dark:text-gray-300">
                        {appointment?.displayValues?.treatmentName}
                      </dd>
                    </div>
                    <div>
                      <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 dark:text-gray-300">
                        Duration
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 dark:text-gray-300">
                        {durationDisplay(appointment?.duration)}
                      </dd>
                    </div>
                  </>
                )}
              </div>
              {appointment?.notes ? (
                <fieldset className="mt-6 border rounded-lg  border-gray-300 pt-2 pb-6 px-6 bg-inherit">
                  <legend className="font-sans font-bold">Notes</legend>
                  <div>{appointment?.notes}</div>
                </fieldset>
              ) : null}
            </dl>
          </div>
        ) : (
          <NotFound />
        )}
      </div>
      <div className="text-right font-light text-xs text-black">
        {disabledEvent ? ChiroUpAppointmentCommon.cannotModify : ''}
      </div>
      <PatientCreateModal
        isOpen={newPatientModalState === OpenClosedStates.Open}
        close={newPatientClose}
        lastName={``}
        stayOnRoute={true}
        closeSearch={() => {}}
        appointmentPanel
      />
      <ConfirmModal
        isOpen={!!doubleBookErrorMessage?.length}
        confirm={doubleBookConfirm}
        close={() => {
          setDoubleBookErrorMessage?.([]);
        }}
        loading={doubleBooking}
        title={`${doubleBookRoomNames || 'Room'} already booked`}
        confirmText={appointment?.id ? 'Update appointment' : 'Book room'}
      >
        <div className="grid grid-cols-1 gap-3 mt-8">
          <ul className="divide-y divide-gray-200">
            {doubleBookErrorMessage?.map((msg: RoomStatus, i: number) => (
              <li
                key={i}
                className="flex flex-col gap-y-1 sm:gap-y-0 sm:flex-row justify-between gap-x-6 py-5"
              >
                <div className="min-w-0 flex-auto">
                  <p className="text-sm/6 text-gray-900">
                    <Link
                      to={`/patients/${msg?.patientId}`}
                      className="hover:underline font-semibold"
                    >
                      {msg?.patientName}
                    </Link>
                    <Link
                      to={`/schedule?id=${msg?.appointmentId}`}
                      className="mt-1 flex text-xs/5 text-gray-500 hover:underline"
                    >
                      {msg?.startTime} - {msg?.endTime}
                    </Link>
                  </p>
                </div>
                <div className="flex shrink-0 items-center gap-x-6">
                  <div className="flex flex-col sm:items-end">
                    <p className="text-xs/5 text-gray-500">
                      {msg?.clinicianName}
                    </p>
                    <p className="mt-1 flex text-xs/5 text-gray-500 ">
                      {msg?.treatmentName}
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </ConfirmModal>
      {/* <pre>
        {ChiroUpJSON.pretty({
          topDisciplineId: value?.disciplineId,
          firstSlot: value?.slots?.[0],
          // isDirty,
          // isSubmitting,
          // okayToSave,
          // localErrors,
          // treatmentHash,
          // slotOptions,
          // disciplineOptions,
          primaryDisciplineOptions,
          // cancelClone,
          // errors,
        })}
      </pre> */}
      <MakeBrowserWait isWaiting={isSubmitting} />
    </div>
  );
};

export default ScheduleAppointmentForm;
