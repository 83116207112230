/**
 *  parentId
 *     The id of the parent object, probably an appointment id or a billingKey.
 *
 * parentIsa
 *     Used to know what the parent is, transaction, encounter and appointment
 *     are supported.
 *
 *  patientId
 *     Nothing special here either.
 *
 *  clinicId
 *     The usual.
 *
 *  readonly = false
 *     Should be no surprises what this does.
 *
 *  plan
 *     This is a plan from the encounter wizard. It will replace/build all the
 *     services from this plan if it exists. An import button is visible if
 *     this exists.
 *
 *  horizontal = false,
 *     Is the display horizontal or vertical?
 *
 *  omitTitle = false
 *     Keep the title from showing.
 *
 *  omitDiagnosisHeader = false
 *     Keep the header out of the left side.
 *
 *  lockButtonText = 'Lock'
 *     What the text on the lock button should be.
 *
 *  unlockButtonText = 'Unlock'
 *     What the text on the unlock button should be.
 *
 *  saveButtonText = 'Save'
 *     What the text on the save button should be.
 *
 *  saveCallback
 *     After a save is done, if this exists, it is called. Use this to do
 *     what you want after a save.
 *
 *  saveVisit
 *     So this component can save a visit.
 *
 *  visitForm
 *     The visit form from an encounter.
 *
 *  assessmentCodes
 *     Appears to be a list of codes that are used to build the list of
 *     diagnoses for the encounter. (Diagnosis do not exist on transactions
 *     except as attached to each service. This makes it look likey they
 *     are.)
 *
 *  triggerAppendItems
 *     A useEffect watches this and when it changes and is not null, it will
 *     append anything sitting in this object to the services. Basically, so
 *     a parent component to call this component and pass in a list of items
 *     to add.
 *
 *  patientTransaction
 *     Cannot be null.
 *
 *  disciplineId = undefined
 *     Pretty sure this is unused at this point. Exists to pass the disciplineId
 *     down to the services.
 *
 *  persistDiagnosesList = false
 *     In the SOAP wizard, a diagnosis can be selected. The requested behavior
 *     was to keep diagnoses around _without_ having a service attached to them.
 *     This was added to allow the bahvior to be controlled as desired.
 *
 *  appointment = null
 *     Presumably to get the insurance information from the appointment.
 *
 *  lockButtonComponent = null [deprecated, use instanceComponents.lockButton]
 *     Allows a custom lock button to be passed in. (All in support of having
 *     one set of buttons whether this component does the save or some other
 *     component that includes this component does the saving -- transactions).
 *
 *  importDiagnosesAlways = false
 *     Makes for a diagnosis list when there are no services. Mostly for the
 *     encounter where a diagnosis can be associated with a plan and they want
 *     the diagnosis to appear as an option _before_ any services are added.
 *
 *  setVisitForm = undefined
 *     A way to update the visit form.
 *
 *  importButtonText = No default.
 *
 *  importOnlyMode = false
 *     Turns off the display of everything but the import button and saves the
 *     transaction after the import in one step.
 *
 *  saveSuccessMessage = undefined
 *     If this exists, it is used as the success message for the save.
 */
import { FeatureFlags } from '@chiroup/core/constants/flags';
import {
  STRING_ANY_HASH,
  STRING_BOOLEAN_HASH,
  STRING_NUMBER_HASH,
} from '@chiroup/core/constants/globals';
import { ChiroUpJSON } from '@chiroup/core/functions/ChiroUpJSON';
import { classNames } from '@chiroup/core/functions/classNames';
import {
  applicableCodes,
  isActiveTreatment,
} from '@chiroup/core/services/businessRules.service/autoPopulateMedicareModifier';
import { AppointmentInsuranceType } from '@chiroup/core/types/Appointment.type';
import { CodeSets } from '@chiroup/core/types/BillingCode.type';
import { ClinicCaseType } from '@chiroup/core/types/ClinicCaseType.type';
import {
  DisciplineInsuranceBenefit,
  Insurance,
} from '@chiroup/core/types/PatientInsurance.type';
import {
  PatientTransaction,
  PatientTransactionItemDiagnosisType,
  PatientTransactionItemType,
  TransactionAppendItemsType,
  TransactionItemSubtypeEnum,
  TransactionItemTypeEnum,
  TransactionPayorCodeType,
  isaServiceItem,
  notaServiceItem,
} from '@chiroup/core/types/PatientTransaction.type';
import { Visit, VisitPlanType } from '@chiroup/core/types/PatientVisit.type';
import { UserRoles } from '@chiroup/core/types/User.type';
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  MeasuringStrategy,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useId,
  useMemo,
  useState,
} from 'react';
import { MeContext } from '../../../../../../contexts/me.context';
import {
  ToastContext,
  ToastTypes,
} from '../../../../../../contexts/toast.context';
import modalityService from '../../../../../../services/modality.service';
import supplementService from '../../../../../../services/supplement.service';
import testService from '../../../../../../services/test.service';
import { databaseService } from '../../../../../settings/database/database.service';
// import useInsuranceList from '../../../billing/hooks/useInsuranceList';
import {
  InstanceComponents,
  RootCallbackEvent,
  RootCallbackProps,
} from '../../../billing/transactions/ConsolidatedTransactionModal';
import CodeEncounterDiagnosisServicesList from './CodeEncounterDiagnosisServicesList';
import CodeEncounterDiagnosisServicesWithDiagnosis from './CodeEncounterDiagnosisServicesWithDiagnosis';

type _restActiveType = { loadPlan: boolean; save: boolean; lock: boolean };

export type InsuranceError = {
  index: number;
  field: keyof AppointmentInsuranceType;
  message: string;
};
export type DndServiceDiagnosisType = {
  data: PatientTransactionItemDiagnosisType;
  services?: string[];
  ord?: number;
};

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};

const priority: { [key: string]: number } = {
  '99202': 5500,
  '99203': 5400,
  '99204': 5300,
  '99212': 5200,
  '99213': 5100,
  '99214': 5000,
  '99211': 4900,
  '98942': 4800,
  '98941': 4700,
  '97813': 4600,
  '97810': 4500,
  '20561': 4400,
  '98940': 4300,
  '98943': 4200,
  '97140': 4100,
  '97124': 4000,
  '20560': 3900,
  '97112': 3800,
  '97110': 3700,
  '97530': 3600,
  modalities: 2000,
  imagingAndTests: 1000,
  supplementsAndSupplies: 100,
};

type Props = {
  // absoluteServiceItems?: PatientTransactionItemType[] | null;
  activeCaseSubtype?: boolean;
  activeTabName?: string | null;
  afterImport?: (newTransaction: any) => Promise<void>;
  assessmentCodes?: { code: string; codeSet: CodeSets; description: string }[];
  caseType?: number | null;
  caseTypes?: Partial<ClinicCaseType>[];
  clinicId: number;
  disciplineId?: number;
  findThePackageCredit?: (params: {
    packageId?: number | null;
    items: PatientTransactionItemType[];
    insurances?: Partial<AppointmentInsuranceType>[];
  }) => any;
  hasPackage?: boolean;
  horizontal?: boolean;
  importButtonText?: string;
  importDiagnosesAlways?: boolean | null | undefined;
  importOnlyMode?: boolean;
  instanceComponents?: InstanceComponents | null;
  lockButtonText?: string;
  noCodesMessage?: string;
  omitDiagnosisHeader?: boolean;
  omitTitle?: boolean;
  parentId?: string | null | undefined;
  parentIsa?: string;
  patientId: string;
  patientTransaction?: PatientTransaction | null;
  // patientTransaction?: ConsolidatedTransactionGetResponse;
  persistDiagnosesList?: boolean | null | undefined;
  policies?: Insurance[];
  plan?: VisitPlanType;
  providerId?: string;
  readonly?: boolean;
  rootCallback?: (args: RootCallbackProps) => void;
  saveButtonText?: string;
  saveCallback?: (args: TransactionAppendItemsType) => void;
  saveSuccessMessage?: string;
  saveVisit?: (visit: Visit, canUpdateSigned?: boolean) => Promise<Visit>;
  setVisitForm?: Dispatch<SetStateAction<Visit>>;
  transactionLineItemComponent?: React.ReactNode;
  triggerAppendItems?: TransactionAppendItemsType | null;
  unlockButtonText?: string;
  visitForm?: Visit;
  codeEncounterDiagnosisServicesListProps?: STRING_ANY_HASH;
  editMode?: boolean;
};

const CodeEncounterDiagnosisServices: React.FC<Props> = ({
  afterImport,
  assessmentCodes,
  caseType,
  caseTypes,
  clinicId,
  codeEncounterDiagnosisServicesListProps = {},
  disciplineId,
  findThePackageCredit,
  hasPackage = false,
  horizontal = false,
  importButtonText,
  importDiagnosesAlways = false,
  importOnlyMode,
  instanceComponents = null,
  lockButtonText = 'Lock',
  noCodesMessage,
  omitDiagnosisHeader = false,
  omitTitle = false,
  parentIsa,
  patientId,
  patientTransaction,
  persistDiagnosesList = false,
  plan,
  policies,
  providerId,
  readonly = false,
  rootCallback,
  saveButtonText = 'Save',
  saveCallback,
  setVisitForm,
  transactionLineItemComponent = null,
  unlockButtonText = 'Unlock',
  visitForm,
  editMode = false,
}) => {
  const dndId = useId();
  const { createToast } = useContext(ToastContext);
  const { hasAccess, hasRole } = useContext(MeContext),
    [isFetchingService, setIsFetchingService] = useState(false);
  const [listofDiagnoses, setListofDiagnoses] = useState<
    DndServiceDiagnosisType[]
  >([]);

  const listofServices = useMemo(() => {
    return patientTransaction?.items?.filter(isaServiceItem) || [];
  }, [patientTransaction]);

  const [insuranceErrors /*, setInsuranceErrors*/] = useState<InsuranceError[]>(
    [],
  );

  const [actualDiagnosesLength, setActualDiagnosesLength] = useState<number>(0);

  const locked = useMemo(() => {
    return (
      patientTransaction?.items
        ?.filter(isaServiceItem)
        .some((i) => i?.locked === true) || false
    );
  }, [patientTransaction]);

  // const superBill = useMemo(() => {
  //   return patientTransaction?.superBill || false;
  // }, [patientTransaction]);

  // const payors = useMemo(() => {
  //   return patientTransaction?.payors || [];
  // }, [patientTransaction]);

  const courtesyBilling = useMemo(() => {
    return patientTransaction?.courtesyBilling || false;
  }, [patientTransaction]);

  const insurances = useMemo(() => {
    return patientTransaction?.insurances || [];
  }, [patientTransaction]);

  const allowBillingPriorityChange = useMemo(() => {
    return !!patientTransaction?.allowBillingPriorityChange;
  }, [patientTransaction]);

  const handleBillingProfileChange = async (e: number | undefined | null) => {
    if (parentIsa === 'transaction' || parentIsa === 'appointment') {
      rootCallback?.({
        event: RootCallbackEvent.UpdateSingleTransaction,
        transaction: { ...patientTransaction, billingProfileId: e as number },
      });
    }
  };

  /**
   * A way to detect if we need to use a POST or a PUT and to detect
   * dirty.
   */
  const [isRestActive, setIsRestActive] = useState<_restActiveType>({
    loadPlan: false,
    save: false,
    lock: false,
  });

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
  );

  useEffect(() => {
    const diags: { [key: string]: DndServiceDiagnosisType } = {};
    const listof: DndServiceDiagnosisType[] = [];
    listofServices?.forEach((service: PatientTransactionItemType) => {
      if (!service || !service.diagnoses || service.diagnoses.length === 0) {
        return;
      }
      service.diagnoses?.forEach((diag) => {
        if (!diags[diag.code]) {
          diags[diag.code] = {
            data: diag,
            services: [],
            ord: diag.ord || 9999,
          };
          listof.push(diags[diag.code]);
        }
        diags?.[diag.code]?.services?.push(`${service.code}`);
      });
    });
    setActualDiagnosesLength?.(listof.length);
    if (persistDiagnosesList) {
      setListofDiagnoses((prev) => {
        prev?.forEach((diag, idx) => {
          if (!diags[diag.data.code]) {
            diags[diag.data.code] = {
              data: diag.data,
              services: [],
              ord: diag.ord ?? idx + 1,
            };
            listof.push({
              ...diag,
              ord: diag.data.ord ?? (idx + 1) * 10,
              services: [],
            });
          }
        });
        if (assessmentCodes?.length && importDiagnosesAlways) {
          // Try to push the assessment codes to the end.
          assessmentCodes?.forEach((diag, idx) => {
            if (!diags[diag.code]) {
              listof.push({
                data: {
                  ...diag,
                  id: -1,
                  itemId: -1,
                  ord: 1000 + (prev.length + idx + 1),
                },
                services: [],
                ord: 1000 + (listofDiagnoses?.length + 1 + idx) * 100,
              });
              diags[diag.code] = listof[listof.length - 1];
            }
          });
        }
        return listof.sort((a, b) => (a.ord || 0) - (b.ord || 0));
      });
    } else {
      setListofDiagnoses(listof.sort((a, b) => (a.ord || 0) - (b.ord || 0)));
    }
  }, [
    listofServices,
    assessmentCodes,
    persistDiagnosesList,
    importDiagnosesAlways,
    listofDiagnoses?.length,
    setActualDiagnosesLength,
  ]);

  const loadFromPlanAndAssessment = useCallback(
    async (e: any, afterLoad?: (newTransaction: any) => Promise<void>) => {
      let payors;
      /**
       * All these take clinicId and an array of ids.
       */
      const getMethods: { [key: string]: any } = {
        supplementsAndSupplies: (ids: number[]) => {
          if (!ids || ids.length === 0) return Promise.resolve([]);
          return supplementService.getMany(clinicId, ids);
        },
        imagingAndTests: (ids: number[]) => {
          if (!ids || ids.length === 0) return Promise.resolve([]);
          return testService.getMany(clinicId, ids);
        },
        modalities: (ids: number[]) => {
          if (!ids || ids.length === 0) return Promise.resolve([]);
          return modalityService.getMany(clinicId, ids);
        },
        codes: (ids: string[]) => {
          if (!ids || ids.length === 0) return Promise.resolve({ data: [] });
          return databaseService.getCodes({
            clinicId: clinicId,
            codes: ids,
            payors: true,
          });
        },
      };

      e.preventDefault();
      const referencedServices: { [key: string]: PatientTransactionItemType } =
        {};
      const loadIds: { [key: string]: string[] } = {};
      const unitsByCode: { [key: string]: number } = {};

      /**
       * These need REST calls to go get the services that were associated
       * with them.
       */

      ['supplementsAndSupplies', 'imagingAndTests', 'modalities'].forEach(
        (key) => {
          Object.keys(plan?.[key] || {}).forEach((i) => {
            const units = plan?.[key]?.[i]?.units || 0;
            if (units > 0) {
              if (!loadIds[key]) {
                loadIds[key] = [];
              }
              loadIds[key].push('' + parseInt(i));
              unitsByCode[i] = units;
            }
          });
        },
      );

      const proms: Promise<any>[] = [];
      const categoryCodeHash: { [key: string]: string } = {};
      /**
       * Earlier, we saved unitsByCodes by the row ID, now we need to have
       * it by the actually code string. So, this _should_ look for reference
       * codes on anything that goes and gets records and load them into
       * unitsByCode as well for later.
       */
      Object.keys(loadIds).forEach((key) => {
        if (getMethods[key] && loadIds[key] && loadIds[key].length > 0) {
          proms.push(
            getMethods[key](loadIds[key]).then((r: any) => {
              if (r && Array.isArray(r)) {
                r.forEach((s: any) => {
                  const id = (s as any).ID || (s as any).id || 0,
                    units = unitsByCode[id] || 1,
                    codes = (s as any).referenceCodes || [];
                  if (codes.length > 0) {
                    codes.forEach((code: any) => {
                      categoryCodeHash[code.code] = key;
                      unitsByCode[code.code] = units;
                    });
                  }
                });
              } else if (r) {
                const id = (r as any).ID || (r as any).id || 0,
                  units = unitsByCode[id] || 1,
                  codes = (r as any).referenceCodes || [];
                if (codes.length > 0) {
                  codes.forEach((code: any) => {
                    categoryCodeHash[code.code] = key;
                    unitsByCode[code.code] = units;
                  });
                }
              }
              return r;
            }),
          );
        }
      });

      setIsRestActive((p) => ({ ...p, loadPlan: true }));
      const responses = await Promise.all(proms);
      if (responses && Array.isArray(responses)) {
        responses.forEach((r: any) => {
          if (r.data && Array.isArray(r.data)) {
            r.data.forEach((s: any) => {
              referencedServices[s.code] = s;
            });
          } else if (r.referenceCodes && Array.isArray(r.referenceCodes)) {
            r.referenceCodes.forEach((rc: any) => {
              referencedServices[rc.code] = rc;
            });
          } else if (r && Array.isArray(r)) {
            r.forEach((s: any) => {
              if (s.referenceCodes && Array.isArray(s.referenceCodes)) {
                s.referenceCodes.forEach((rc: any) => {
                  referencedServices[rc.code] = rc;
                });
              }
            });
          }
        });
      }

      let key = 'evaluationAndManagement';
      let temp = plan?.[key] || {};

      Object.keys(temp).forEach((i) => {
        const $ptr = temp[i] || {};
        const units = $ptr.units || 0;
        if (units > 0) {
          if ($ptr.unitServices) {
            Object.keys($ptr.unitServices).forEach((k) => {
              const $ptr2 = $ptr.unitServices[k] || [];
              $ptr2.forEach((code: string) => {
                if (!loadIds.codes) {
                  loadIds.codes = [];
                }
                loadIds.codes.push(code);
                /**
                 * This is where things that have multiple services for each additional
                 * unit are handled. This ONLY handles where the first is one value and
                 * each additional is another.
                 */
                if (k === '__') {
                  unitsByCode[code] = units - 1;
                } else {
                  unitsByCode[code] = 1;
                }
              });
            });
          }
          if ($ptr.services) {
            $ptr.services.forEach((code: string) => {
              if (!loadIds.codes) {
                loadIds.codes = [];
              }
              loadIds.codes.push(code);
              unitsByCode[code] = units || 1;
            });
          }
        }
      });

      key = 'treatments';
      temp = plan?.[key] || {};

      Object.keys(temp).forEach((i) => {
        const $ptr = temp[i] || {};
        Object.keys($ptr).forEach((j) => {
          const $ptr2 = $ptr[j] || {},
            units = $ptr2.units || 0;
          if (units > 0) {
            if ($ptr2.unitServices) {
              const usedServiceUnits: STRING_NUMBER_HASH = {};
              for (let i = 1; i <= units; i++) {
                const key = `${i}`,
                  service = $ptr2.unitServices[key] ?? $ptr2.unitServices.__;
                if (!usedServiceUnits[service]) {
                  usedServiceUnits[service] = 0;
                }
                usedServiceUnits[service]++;
              }

              const services = Object.keys(usedServiceUnits);
              services.forEach((code: string) => {
                if (!loadIds.codes) {
                  loadIds.codes = [];
                }
                loadIds.codes.push(code);
                unitsByCode[code] = usedServiceUnits[code];
              });
            } else if ($ptr2.services) {
              $ptr2.services.forEach((code: string) => {
                if (!loadIds.codes) {
                  loadIds.codes = [];
                }
                loadIds.codes.push(code);
                unitsByCode[code] = units || 1;
              });
            }
          }
        });
      });

      key = 'rehab';
      temp = plan?.[key] || {};

      Object.keys(temp).forEach((i) => {
        const $ptr = temp[i] || {},
          units = $ptr.units || 0,
          services = $ptr.services || [];
        if (units > 0 && services.length > 0) {
          services.forEach((code: string) => {
            if (!loadIds.codes) {
              loadIds.codes = [];
            }
            loadIds.codes.push(code);
            unitsByCode[code] = units || 1;
          });
        }
      });

      const allUsedServiceCodes: string[] = [
        ...(loadIds.codes || []),
        ...Object.keys(referencedServices || {}),
      ];

      let completeListofServices = [];
      try {
        const { data } = (await getMethods.codes(allUsedServiceCodes)) ?? {
          data: [],
        };
        completeListofServices = data;

        if (data?.payors?.length) {
          payors = data?.payors;
        }
      } catch (e) {
        console.error({ e });
        createToast({
          title: 'Fetch Error!',
          description: <>The network request failed.</>,
          type: ToastTypes.Fail,
          duration: 5000,
        });
      }

      /**
       * Not sure it was a good choice, but the service can return an array
       * if there are multiple services, otherwise just a singleton. Need
       * to detect that.
       */

      const sorted = Array.isArray(completeListofServices)
        ? (completeListofServices || [])
            .map((c: any, index: number) => {
              return {
                ...c,
                units: unitsByCode[c.code],
                diagnosis: c.diagnosis || [],
                type: TransactionItemTypeEnum.Debit,
                subtype: TransactionItemSubtypeEnum.Service,
                weight:
                  priority?.[c.code] ||
                  priority?.[categoryCodeHash[c.code]] + index ||
                  0,
              };
            })
            .sort((a, b) => (b.weight || 0) - (a.weight || 0))
        : [
            {
              ...completeListofServices,
              type: TransactionItemTypeEnum.Debit,
              subtype: TransactionItemSubtypeEnum.Service,
              units:
                completeListofServices && completeListofServices.code
                  ? unitsByCode[completeListofServices.code]
                  : 1,
            },
          ];

      sorted?.forEach((service) => {
        const defaultModifiers = [
          service.modifier1,
          service.modifier2,
          service.modifier3,
          service.modifier4,
        ].filter((m) => !!m);

        const newModifiers = insurances?.reduce((arr, insurance) => {
          if (arr.length >= 4) return arr;

          const payorInfo = service.payors.find(
            (payor: any) => payor.payorIntPk === insurance.payorID,
          );

          if (!payorInfo) return arr;

          if (payorInfo.modifier1) {
            const modifierOneExists = arr.includes(payorInfo.modifier1);
            if (!modifierOneExists) arr.push(payorInfo.modifier1);
          }

          if (payorInfo.modifier2) {
            const modifierTwoExists = arr.includes(payorInfo.modifier2);
            if (!modifierTwoExists) arr.push(payorInfo.modifier2);
          }

          if (payorInfo.modifier3) {
            const modifierThreeExists = arr.includes(payorInfo.modifier3);
            if (!modifierThreeExists) arr.push(payorInfo.modifier3);
          }

          if (payorInfo.modifier4) {
            const modifierFourExists = arr.includes(payorInfo.modifier4);
            if (!modifierFourExists) arr.push(payorInfo.modifier4);
          }
          // Limit to four modifiers
          if (arr.length > 4) {
            arr = arr.slice(0, 4);
          }

          return arr;
        }, defaultModifiers || []);

        newModifiers?.forEach((modifier, i) => {
          service[`modifier${i + 1}`] = modifier;
        });
      });

      const diags: { [key: string]: DndServiceDiagnosisType } = {};
      const listof: DndServiceDiagnosisType[] = [];

      const medicareInsurance = insurances?.some(
        (i: any) =>
          (i?.insuranceProgram ?? '').indexOf('mcare') === 0 ||
          (i?.insuranceProgram ?? '').indexOf('mcaid') === 0 ||
          (i?.insuranceProgram ?? '').indexOf('hmomedicare') === 0,
      );
      //this is a hack, we should not need to map and add the modifier here,
      //TODO [BWM, use serviceMagic] but I couldn't get the transactionMagic function to work correctly when loading from plan.
      const finalServices = sorted
        .filter((s) => !!s && !!s.code)
        .map((service: PatientTransactionItemType) => {
          const obj = { ...service, insuranceBillable: true };
          if (
            service?.code &&
            applicableCodes?.[service?.code] &&
            medicareInsurance &&
            isActiveTreatment({ caseSubtype: patientTransaction?.caseSubtype })
          ) {
            obj.modifier1 = 'AT';
          }
          return obj;
        });

      if (assessmentCodes?.length) {
        assessmentCodes?.forEach((diag, idx) => {
          if (!diags[diag.code]) {
            diags[diag.code] = {
              data: {
                ...diag,
                id: -1,
                itemId: -1,
                ord: 1000 + 1 + idx,
              },
              services: [],
              ord: 1000 + (listofDiagnoses?.length + 1 + idx) * 100,
            };
            listof.push(diags[diag.code]);
          }
        });
        if (persistDiagnosesList) {
          const listOfDiagnosesNew = listofDiagnoses || [];
          listOfDiagnosesNew.forEach((diag, idx) => {
            if (!diags[diag.data.code]) {
              diags[diag.data.code] = diag;
              diag.ord = diag?.services?.length ? (idx + 1) * 10 : 1001 + idx; // try and sort unused diags at the end
              listof.push(diag);
            }
          });
          setListofDiagnoses(listof);

          setVisitForm?.((prev) => ({
            ...prev,
            importDiagnosesAlways: true,
          }));
        } else {
          setListofDiagnoses(listof);
        }
      }

      const newInsurances = JSON.parse(JSON.stringify(insurances));
      for (const insurance of newInsurances) {
        for (const service of sorted) {
          if (!insurance.serviceAllowedAmounts?.[service.code]) {
            const servicePayor = service.payors.find(
              (payor: any) => payor.payorIntPk === insurance.payorID,
            );
            insurance.serviceAllowedAmounts = {
              ...(insurance.serviceAllowedAmounts || {}),
              [String(service.code)]: {
                allowedAmount:
                  servicePayor?.allowedAmount || service?.billedAmount || 0,
                payorID: servicePayor?.payorIntPk,
              },
            };
          }
        }
      }

      // setInsurancesMitm(newInsurances);

      const newTransaction = {
        ...patientTransaction,
        items: [
          ...(patientTransaction?.items?.filter(notaServiceItem) ?? []),
          ...finalServices,
        ],
        services: finalServices,
        insurances: newInsurances,
        payors: payors ?? patientTransaction?.payors ?? [],
      };

      rootCallback?.({
        event: RootCallbackEvent.UpdateSingleTransaction,
        transaction: newTransaction,
      });

      if (afterLoad) await afterLoad([newTransaction]);

      setIsRestActive((p) => ({ ...p, loadPlan: false }));

      return {
        services: finalServices,
        insurances: newInsurances,
      };
    },
    [
      plan,
      insurances,
      rootCallback,
      patientTransaction,
      assessmentCodes,
      clinicId,
      createToast,
      persistDiagnosesList,
      listofDiagnoses,
      setVisitForm,
    ],
  );

  const handleDragOver = (event: DragOverEvent) => {
    if (locked || readonly) return;
    const { active, over } = event;
    if (!over || !active) return;
    const { id: activeId } = active;
    const { id: overId } = over;
    const isDraggingService = active.data.current?.type === 'service';
    const isDraggingDiagnosis = active.data.current?.type === 'diagnosis';
    if (!isDraggingService && !isDraggingDiagnosis) return;

    if (isDraggingService) {
      const activeIndex = listofServices.findIndex(
        (i: any) => i.code === activeId,
      );
      const overIndex = listofServices.findIndex((i: any) => i.code === overId);
      // setListofServices((prev: any) => arrayMove(prev, activeIndex, overIndex));

      rootCallback?.({
        event: RootCallbackEvent.UpdateSingleTransaction,
        transaction: {
          ...patientTransaction,
          items: arrayMove(
            patientTransaction?.items || [],
            activeIndex,
            overIndex,
          ),
        },
      });
    } else if (isDraggingDiagnosis) {
      const isOverService = over.data.current?.type === 'service';
      const diagnosesWithoutServices = listofDiagnoses.reduce((a, c) => {
        if (!c.services || c.services.length === 0) {
          a[c.data.code] = c;
        }
        return a;
      }, {} as STRING_ANY_HASH);

      if (!isOverService && !diagnosesWithoutServices[activeId]) {
        const activeIndex = listofDiagnoses.findIndex(
          (i) => i.data.code === activeId,
        );
        const overIndex = listofDiagnoses.findIndex(
          (i) => i.data.code === overId,
        );

        const moved = arrayMove(listofDiagnoses, activeIndex, overIndex),
          weights: STRING_NUMBER_HASH = {};
        moved.forEach((diag: any, idx: number) => {
          if (!diag) return;
          if (!diag.data) return;
          diag.ord = // Unused diags at the end
            diag?.services?.length
              ? (weights[diag?.data?.code] = (idx + 1) * 10)
              : 1001 + idx;
          diag.data.ord = diag.ord;
        });
        setListofDiagnoses(moved);
        const nonServiceItems =
            patientTransaction?.items?.filter((i) => !isaServiceItem(i)) ?? [],
          newServices = patientTransaction?.items?.filter(isaServiceItem) || [];
        newServices.forEach((service) => {
          if (!service.diagnoses) service.diagnoses = [];
          service.diagnoses = service.diagnoses.sort(
            (a: any, b: any) => (weights[a.code] || 0) - (weights[b.code] || 0),
          );
        });

        rootCallback?.({
          event: RootCallbackEvent.UpdateSingleTransaction,
          transaction: {
            ...patientTransaction,
            items: [...nonServiceItems, ...newServices],
            services: newServices,
          },
        });
        // setListofServices((prev: any) => {
        //   const newServices = [...(prev || [])];
        //   newServices.forEach((service) => {
        //     if (!service.diagnoses) service.diagnoses = [];
        //     service.diagnoses = service.diagnoses.sort(
        //       (a: any, b: any) =>
        //         (weights[a.code] || 0) - (weights[b.code] || 0),
        //     );
        //   });
        //   return newServices;
        // });
      }
    }
  };

  const handleDragEnd = (event: DragEndEvent) => {
    if (locked || readonly) {
      createToast({
        title: 'Locked!',
        description: <>Locked services may not be changed.</>,
        type: ToastTypes.Info,
        duration: 5000,
      });
      return;
    }
    const { active, over } = event;

    if (!over || !active) return;
    const isDraggingDiagnosis = active.data.current?.type === 'diagnosis';
    const isOverService = over.data.current?.type === 'service';

    if (isDraggingDiagnosis && typeof rootCallback === 'function') {
      const items = event?.active?.data?.current?.sortable?.items || [];
      const weights = items.reduce((obj: any, i: string, idx: number) => {
        obj[i] = (idx + 1) * 20;
        return obj;
      }, {});

      const diagsByCode: STRING_ANY_HASH = listofDiagnoses.reduce((a, c) => {
        a[c.data.code] = c;
        return a;
      }, {} as STRING_ANY_HASH);

      const newobj = ChiroUpJSON.clone(patientTransaction || {});
      newobj.items = newobj.items?.map((item: any) => {
        if (isaServiceItem(item)) {
          item.diagnoses = item.diagnoses ?? [];
          const usedDiags: STRING_BOOLEAN_HASH = {};
          item.diagnoses = item.diagnoses.map((d: any) => {
            const weight = weights[d.code];
            d.ord = weight;
            usedDiags[d.code] = true;
            return d;
          });
          if (item.code === over?.id) {
            if (!usedDiags[active?.id]) {
              const data = diagsByCode[active?.id].data;
              item.diagnoses.push({
                code: data.code,
                codeSet: data.codeSet,
                description: data.description,
                ord: weights[active?.id],
              });
            }
          }
        }
        return item;
      });
      newobj.services = newobj.items?.filter(isaServiceItem) || [];

      rootCallback({
        event: RootCallbackEvent.UpdateSingleTransaction,
        transaction: newobj,
      });

      return;
    } else if (!isDraggingDiagnosis && typeof rootCallback === 'function') {
      const newobj = ChiroUpJSON.clone(patientTransaction || {});
      newobj.items =
        newobj.items?.filter((item: any) => !isaServiceItem(item)) || [];
      newobj.items = newobj.items.concat(listofServices);
      newobj.services = listofServices;
      rootCallback({
        event: RootCallbackEvent.UpdateSingleTransaction,
        transaction: newobj,
      });
      return;
    }
    if (!isDraggingDiagnosis || !isOverService) return;
    const { id: activeId } = active;
    const { id: overId } = over;
    const activeIndex = listofDiagnoses.findIndex(
      (i) => i.data.code === activeId,
    );
    const overIndex = listofServices.findIndex((i: any) => i.code === overId);
    const diagnosisDragging = listofDiagnoses[activeIndex];
    const serviceOver = listofServices[overIndex];
    const diagnosisAlreadyThere = serviceOver.diagnoses?.some(
      (d: any) => d.code === diagnosisDragging.data.code,
    );
    if (diagnosisAlreadyThere) return;

    const newobj = ChiroUpJSON.clone(patientTransaction || {});
    newobj.items = newobj.items?.map((item: any) => {
      if (isaServiceItem(item)) {
        if (item.code !== serviceOver.code) return item;
        item = {
          ...item,
          diagnoses: (item.diagnoses || []).concat(diagnosisDragging.data),
        };
      }
      return item;
    });
    newobj.services = newobj.items?.filter(isaServiceItem) || [];

    rootCallback?.({
      event: RootCallbackEvent.UpdateSingleTransaction,
      transaction: newobj,
    });
  };

  const addDiagnosisToAllServices = (
    _: React.MouseEvent<HTMLDivElement>,
    diagnosis: PatientTransactionItemDiagnosisType,
  ) => {
    // TODO: They are going to want to be able to add the diagnosis across all services in any time slot.
    const clone = ChiroUpJSON.clone(patientTransaction) as PatientTransaction,
      newItems = clone?.items?.map((item) => {
        if (!isaServiceItem(item)) return item;
        const newDiagnoses = item.diagnoses || [];
        if (!newDiagnoses.some((d) => d.code === diagnosis.code)) {
          newDiagnoses.push(diagnosis);
        }
        return {
          ...item,
          diagnoses: newDiagnoses,
        };
      });

    // setListofServices(newServices);
    clone.items = newItems;
    clone.services = newItems.filter(isaServiceItem);

    rootCallback?.({
      event: RootCallbackEvent.UpdateSingleTransaction,
      transaction: clone,
    });
  };

  const handlePolicyChange = useCallback(
    (selectedPolicy: Insurance) => {
      if (!selectedPolicy) return;
      const disciplineBenefits = selectedPolicy.disciplineBenefits?.find(
        (db: DisciplineInsuranceBenefit) => db.disciplineId === disciplineId,
      );
      const localPayors = (patientTransaction?.payors ??
        []) as unknown as TransactionPayorCodeType[];
      const serviceAllowedAmounts = listofServices.reduce(
        (obj: any, service: any) => {
          const serviceCode = service.code;
          if (!serviceCode) return obj;
          const payorForThisPolicyServiceCombo = [
            ...(service.payors || []),
            ...(localPayors || []),
          ]?.find(
            (payor: any) =>
              (payor.payorIntPk || payor.payorId) === selectedPolicy.payor &&
              payor.code === serviceCode,
          );
          const hasAllowedAmount =
            payorForThisPolicyServiceCombo?.allowedAmount &&
            Number(payorForThisPolicyServiceCombo?.allowedAmount) !== 0;
          obj[serviceCode] = {
            allowedAmount: hasAllowedAmount
              ? payorForThisPolicyServiceCombo?.allowedAmount
              : service.billedAmount || service?.amount || 0,
          };
          return obj;
        },
        {},
      );

      const newi = ChiroUpJSON.clone(insurances);

      const newInsurance = {
        insuranceID: selectedPolicy.id,
        insuranceName: selectedPolicy?.name,
        insuranceProgram: selectedPolicy?.insuranceProgram,
        coPay: disciplineBenefits?.coPay || 0,
        coInsurance: disciplineBenefits?.coInsurance || 0,
        deductible: selectedPolicy.deductible || 0,
        billingPriority: newi.length + 1,
        payorID: selectedPolicy.payor,
        inNetwork: selectedPolicy.inNetwork,
        billable: selectedPolicy.billable,
        serviceAllowedAmounts,
        maxPerVisit: selectedPolicy.maxPerVisit || null,
      };

      const newTxn = {
        ...patientTransaction,
        insurances: !selectedPolicy.billable
          ? [newInsurance]
          : [...newi, newInsurance],
      };

      rootCallback?.({
        event: RootCallbackEvent.UpdateSingleTransaction,
        transaction: newTxn,
      });
    },
    [
      disciplineId,
      insurances,
      listofServices,
      patientTransaction,
      rootCallback,
    ],
  );

  const handleCourtesyBillingChange = (e: boolean) => {
    rootCallback?.({
      event: RootCallbackEvent.UpdateSingleTransaction,
      transaction: {
        ...patientTransaction,
        courtesyBilling: e,
        superBill: e ? false : patientTransaction?.superBill,
      },
    });
  };

  const handleSuperBillChange = (e: boolean) => {
    rootCallback?.({
      event: RootCallbackEvent.UpdateSingleTransaction,
      transaction: {
        ...patientTransaction,
        courtesyBilling: e ? false : patientTransaction?.courtesyBilling,
        superBill: e,
      },
    });
  };

  if (
    !hasAccess(FeatureFlags.ehr) ||
    !hasRole([UserRoles.Admin, UserRoles.Provider, UserRoles.Biller])
  ) {
    return (
      <div className="ml-5 text-gray-400">
        <cite>You do not have access to this feature.</cite>
      </div>
    );
  }

  return (
    <DndContext
      id={dndId}
      sensors={sensors}
      collisionDetection={closestCenter}
      measuring={measuring}
      onDragEnd={handleDragEnd}
      onDragOver={handleDragOver}
    >
      <div
        data-id="code-encounter-diagnosis-services"
        className={classNames(
          'w-full flex gap-4',
          horizontal ? 'flex-row' : 'flex-col',
        )}
      >
        <CodeEncounterDiagnosisServicesWithDiagnosis
          afterImport={afterImport}
          allowBillingPriorityChange={allowBillingPriorityChange}
          billingProfileId={patientTransaction?.billingProfileId}
          caseType={caseType}
          caseTypes={caseTypes}
          courtesyBilling={courtesyBilling}
          createToast={createToast}
          editMode={editMode}
          diagnosticCodeComponent={
            <CodeEncounterDiagnosisServicesList
              omitDiagnosisHeader={omitDiagnosisHeader}
              actualDiagnosesLength={actualDiagnosesLength}
              listofDiagnoses={listofDiagnoses}
              addDiagnosisToAllServices={addDiagnosisToAllServices}
              isBillingStarted={patientTransaction?.isBillingStarted}
              readonly={readonly}
              locked={locked}
              importOnlyMode={importOnlyMode}
              {...codeEncounterDiagnosisServicesListProps}
            />
          }
          disciplineId={disciplineId}
          findThePackageCredit={findThePackageCredit}
          handleBillingProfileChange={handleBillingProfileChange}
          handleCourtesyBillingChange={handleCourtesyBillingChange}
          handlePolicyChange={handlePolicyChange}
          handleSuperBillChange={handleSuperBillChange}
          hasPackage={hasPackage}
          horizontal={horizontal}
          importButtonText={importButtonText}
          importOnlyMode={importOnlyMode}
          insuranceErrors={insuranceErrors}
          isBillingStarted={patientTransaction?.isBillingStarted}
          isFetchingService={isFetchingService}
          isRestActive={isRestActive}
          loadFromPlanAndAssessment={loadFromPlanAndAssessment}
          instanceComponents={instanceComponents}
          lockButtonText={lockButtonText}
          locked={locked}
          noCodesMessage={noCodesMessage}
          omitTitle={omitTitle}
          parentIsa={parentIsa}
          patientId={patientId}
          patientTransaction={patientTransaction}
          plan={plan}
          policies={policies}
          providerId={providerId || visitForm?.clinician?.id || ''}
          readonly={readonly}
          rootCallback={rootCallback}
          saveButtonText={saveButtonText}
          saveCallback={saveCallback}
          services={listofServices}
          setIsFetchingService={setIsFetchingService}
          transactionLineItemComponent={transactionLineItemComponent}
          unlockButtonText={unlockButtonText}
          insurances={insurances}
        />
        {/* <pre>{ChiroUpJSON.pretty({ listofServices })}</pre> */}
      </div>
    </DndContext>
  );
};

export default CodeEncounterDiagnosisServices;
