import {
  isaServiceItem,
  isaTreatmentItem,
  PatientTransaction,
} from '@chiroup/core/types/PatientTransaction.type';
import { MagicAction, MagicActionType } from './commonMagic';
import { TransactionMagiceResponseType } from './transactionMagic';

/**
 *
 */
export const fnTransactionZeroTreatment = (
  options: any,
  payload: {
    original: PatientTransaction;
    current: PatientTransaction;
    previous: PatientTransaction;
  },
): TransactionMagiceResponseType => {
  const actions: MagicActionType[] = [];
  const { original, current: transaction } = payload;
  const trace = options?.trace ?? false;

  if (options?.trace) {
    console.log({ fnTransactionZeroTreatment: payload, options });
  }

  if (!transaction) {
    actions.push({
      message: `No actions possible without a transaction.`,
      type: MagicAction.Error,
    });
  }
  let touched = false;

  const originalServicesLength =
    original?.items?.filter((i) => isaServiceItem(i)).length ?? 0;
  const currentServicesLength =
    transaction?.items?.filter((i) => isaServiceItem(i)).length ?? 0;

  if (trace) {
    console.log({
      originalServicesLength,
      currentServicesLength,
    });
  }
  if (originalServicesLength === 0 && currentServicesLength > 0) {
    let setCount = 0;
    transaction.items = transaction.items.map((i) => {
      if (isaTreatmentItem(i)) {
        i.amount = 0;
        ++setCount;
      }
      return i;
    });
    if (setCount > 0) {
      actions.push({
        message: `Treatment amount${setCount === 1 ? '' : 's'} set to $0.`,
        type: MagicAction.Updated,
      });
      touched = true;
    }
  }

  return {
    actions,
    transaction,
    touched,
  };
};
